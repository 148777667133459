import { Stack, FormLabel } from '@mui/material';
import { Loader, Card } from '../../components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { signEstimateAgreement } from '../../fetch';
import { useState, FC } from 'react';
import { useSnackbar } from 'notistack';
import { IAgreementData, IAgreementStatus } from '../../models';
import { PaymentMethod } from './payment-method';
import { Signature } from './signature';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { generateUUID } from '../../helpers';

const Schema = yup.object().shape({
  name: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  street: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  city: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  state: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  zipCode: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  cardNumber: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  expirationMonth: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  expirationYear: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  cvv: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) =>
      selectedTab === 0 && creditCardRequired,
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  printedName: yup.string().required('Required'),
  hasConfirmedAgreement: yup.boolean().required('Required'),
  hasConfirmedAuthorization: yup.boolean().required('Required'),
  recurringPaymentId: yup.mixed().when(['selectedTab', 'creditCardRequired'], {
    is: (selectedTab: number, creditCardRequired: boolean) => {
      return selectedTab === 1 && creditCardRequired;
    },
    then: yup.string().required('Required'),
    otherwise: yup.string().notRequired().nullable(),
  }),
  selectedTab: yup.string(),
  creditCardRequired: yup.boolean(),
});

const INITIAL_FORM_VALUES = {
  name: '',
  cardNumber: '',
  expirationMonth: '',
  expirationYear: '',
  cvv: '',
  printedName: '',
  hasConfirmedAgreement: false,
  hasConfirmedAuthorization: false,
  recurringPaymentId: '',
  selectedTab: 'new-card',
};

interface IContractPageDetails {
  estimateAgreementStatus?: IAgreementStatus;
  repairId?: string;
  estimateId?: string;
  fetchEstimateAgreementStatus: () => void;
  hasSubmitted: boolean;
  token: string;
  agreementData: IAgreementData;
}

export const ContractPageDetails: FC<IContractPageDetails> = ({
  estimateAgreementStatus,
  repairId,
  estimateId,
  fetchEstimateAgreementStatus,
  hasSubmitted,
  token,
  agreementData,
}) => {
  const classes = useStyles();
  const [sigPadInstance, setSigPadInstance] = useState<SignaturePad | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { adyen } = useFlags();
  const [shouldCallAdyenSession, setShouldCallAdyenSession] = useState<boolean>(false);
  const [showAdyenPaymentSection, setShowAdyenPaymentSection] = useState<boolean>(false);
  const [isLoadingAdyenPaymentSection, setIsLoadingAdyenPaymentSection] = useState<boolean>(false);
  const [guid, setGuid] = useState<string>('');
  const [isInvalidCreditCard, setIsInvalidCreditCard] = useState(false);

  const setInvalidCreditCard = (value: boolean) => {
    setIsInvalidCreditCard(value);
  };

  const handleAdyenAgreeAndContinue = () => {
    setIsLoadingAdyenPaymentSection(true);
    setShouldCallAdyenSession(true);
    setGuid(generateUUID());

    setTimeout(() => {
      setShowAdyenPaymentSection(true);
      setIsLoadingAdyenPaymentSection(false);
      setShouldCallAdyenSession(false);
    }, 2000);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...INITIAL_FORM_VALUES,
        name: estimateAgreementStatus?.accountName ?? '',
        street: estimateAgreementStatus?.addressModel?.street ?? '',
        city: estimateAgreementStatus?.addressModel?.city ?? '',
        state: estimateAgreementStatus?.addressModel?.state ?? '',
        zipCode: estimateAgreementStatus?.addressModel?.postalCode ?? '',
        creditCardRequired: estimateAgreementStatus?.creditCardRequired ? true : false,
      }}
      validationSchema={Schema}
      onSubmit={async (values, actions) => {
        try {
          const formData = new FormData();
          const res = await fetch(sigPadInstance?.toDataURL() as string);
          const blob = await res.blob();
          formData.append('Body.Signature', blob);
          formData.append('Body.Name', values.printedName.trim());
          if (estimateAgreementStatus?.creditCardRequired) {
            if (values.selectedTab === 'new-card' && !adyen) {
              formData.append('Body.CreditCardNumber', values.cardNumber);
              formData.append('Body.ExpirationMonth', values.expirationMonth);
              formData.append('Body.ExpirationYear', values.expirationYear);
              formData.append('Body.Cvv', values.cvv);
              formData.append('Body.Street', values.street);
              formData.append('Body.City', values.city);
              formData.append('Body.State', values.state);
              formData.append('Body.PostalCode', values.zipCode);
            } else if (adyen) {
              formData.append('Body.Street', values.street);
              formData.append('Body.City', values.city);
              formData.append('Body.State', values.state);
              formData.append('Body.PostalCode', values.zipCode);
            } else {
              formData.append('Body.RecurringPaymentId', values.recurringPaymentId);
            }
          }
          formData.append('Body.EstimateId', estimateId as string);
          formData.append('Body.RepairId', repairId as string);
          adyen && formData.append('Body.Reference', guid);
          await signEstimateAgreement(token as string, formData);
          enqueueSnackbar('Service agreement signed!', {
            variant: 'success',
          });
          fetchEstimateAgreementStatus();
        } catch (error: any) {
          enqueueSnackbar(error?.Detail || `Error signing service agreement, please try again.`, {
            variant: 'error',
          });
        } finally {
          // disable the signature canvas
          sigPadInstance?.off();
        }
      }}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        handleSubmit,
        isValid,
        handleBlur,
        validateForm,
      }) => {
        const handleAdyenPaymentComplete = () => {
          handleSubmit();
        };
        return (
          <>
            {isSubmitting && <Loader type="fullscreen" position="centered" />}
            <Form onSubmit={adyen ? handleAdyenPaymentComplete : handleSubmit}>
              {!hasSubmitted && (
                <Stack gap={2} flexDirection={adyen ? 'column-reverse' : 'column'}>
                  <PaymentMethod
                    values={values}
                    setFieldValue={setFieldValue}
                    handleBlur={handleBlur}
                    repairId={repairId}
                    estimateId={estimateId}
                    token={token}
                    validateForm={validateForm}
                    estimateAgreementStatus={estimateAgreementStatus}
                    agreementData={agreementData}
                    guid={guid}
                    shouldCallAdyenSession={shouldCallAdyenSession}
                    showAdyenPaymentSection={showAdyenPaymentSection}
                    afterAdyenPaymentComplete={handleAdyenPaymentComplete}
                    creditCardRequired={estimateAgreementStatus?.creditCardRequired!}
                    setInvalidCard={setInvalidCreditCard}
                  />
                  {!estimateAgreementStatus?.isExpired ? (
                    <Signature
                      values={values}
                      setFieldValue={setFieldValue}
                      hasSubmitted={hasSubmitted}
                      isSubmitting={isSubmitting || isLoadingAdyenPaymentSection}
                      isValid={isValid}
                      sigPadInstance={sigPadInstance}
                      setSigPadInstance={setSigPadInstance}
                      clearSigPadOnResize={false}
                      sigContainerClass={classes.sigContainer}
                      sigCanvasClass={classes.sigPad}
                      officeName={estimateAgreementStatus?.officeName}
                      handleAdyenAgreeAndContinue={handleAdyenAgreeAndContinue}
                      display={adyen && showAdyenPaymentSection ? false : true}
                      isInvalidCreditCard={isInvalidCreditCard}
                    />
                  ) : (
                    <Card
                      cardTitleProps={{
                        title: 'Estimate Expired!',
                      }}
                      sxProps={{ display: true ? 'block' : 'none', color: 'red' }}
                    >
                      <Stack gap={2}>
                        <FormLabel required>
                          This estimate has expired, please contact us for additional information at
                          <br></br>
                          {agreementData.storePhoneNumber}
                        </FormLabel>
                      </Stack>
                    </Card>
                  )}
                </Stack>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  sigContainer: {
    width: '250px',
    //setting several breakpoints for this to account for clearOnResize={false}
    [theme.breakpoints.up('lg')]: {
      width: '300px',
    },
    [theme.breakpoints.up(1400)]: {
      width: '400px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '700px',
    },
    [theme.breakpoints.down('md')]: {
      width: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.down(500)]: {
      width: '300px',
    },
    [theme.breakpoints.down(400)]: {
      width: '250px',
    },
    [theme.breakpoints.down(350)]: {
      width: '200px',
    },
  },
  sigPad: {
    width: '250px',
    height: '150px',
    //setting several breakpoints for this to account for clearOnResize={false}
    [theme.breakpoints.up('lg')]: {
      width: '300px',
    },
    [theme.breakpoints.up(1400)]: {
      width: '400px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '700px',
    },
    [theme.breakpoints.down('md')]: {
      width: '500px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '400px',
    },
    [theme.breakpoints.down(500)]: {
      width: '300px',
    },
    [theme.breakpoints.down(400)]: {
      width: '250px',
    },
    [theme.breakpoints.down(350)]: {
      width: '200px',
    },
  },
  agreementWrapper: {
    '&>.MuiGrid-item': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
  },
}));
