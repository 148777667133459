import { Box, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from 'react';
import { CardTitle, Loader, Card } from '../../components';
import { getScheduledServiceChecklist } from '../../fetch';
import { formatDate } from '../../helpers';
import { IServiceSuggestionChecklist } from '../../models';
import { useUnload } from '../../hooks';

interface IChecklistInfo {
  serviceId: string | number;
  serviceDefId: string | number;
  setChecklistInfo: Dispatch<SetStateAction<IServiceSuggestionChecklist[]>>;
  checklistInfo: IServiceSuggestionChecklist[];
  dirty: boolean;
  isExpanded?: boolean;
  readonly?: boolean;
  noCardList?: boolean;
  showLoading?: boolean;
}

export const ChecklistInfo: FC<IChecklistInfo> = ({
  serviceId,
  serviceDefId,
  checklistInfo,
  setChecklistInfo,
  dirty,
  isExpanded,
  readonly,
  noCardList = false,
  showLoading = true,
}) => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const classes = useStyles();

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, dirty);

  const getChecklistInfo = async () => {
    try {
      setLoading(true);
      const res = await getScheduledServiceChecklist(serviceId, serviceDefId);
      setChecklistInfo(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getChecklistInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceDefId]);

  const checkListGrid = () => {
    return (
      <>
        {isLoading && showLoading && (
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="10rem"
            justifyContent="center"
          >
            <Loader position="centered" />
          </Box>
        )}
        {!isLoading && checklistInfo?.length > 0 && (
          <Box display="flex">
            <Grid className={classes.print} container spacing={1} alignItems="center">
              {checklistInfo.map((info: IServiceSuggestionChecklist, index) => {
                return (
                  <Fragment key={`${index}`}>
                    <Grid item xs={12} sm={4} className="col-print-7">
                      <FormControlLabel
                        control={
                          <>
                            <Checkbox
                              className="print--none"
                              checked={info.isCompleted}
                              id={`${index}-checkbox`}
                              onChange={(_, checked: boolean) => {
                                const copy = JSON.parse(JSON.stringify(checklistInfo));
                                copy[index] = {
                                  ...copy[index],
                                  isCompleted: checked,
                                };
                                setChecklistInfo(copy);
                              }}
                              disabled={readonly}
                            />
                            <div style={{ padding: '4px' }} className="print--only">
                              {info.isCompleted ? 'x' : 'o'}
                            </div>
                          </>
                        }
                        classes={{
                          root: classes.label,
                        }}
                        label={info.description}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8} className="col-print-5">
                      <Typography sx={{ fontSize: 14, fontStyle: 'italic' }}>
                        Previous:{' '}
                        {info?.whenPreviouslyCompleted
                          ? formatDate(info?.whenPreviouslyCompleted)
                          : ''}
                      </Typography>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </Box>
        )}
        {!isLoading && checklistInfo?.length === 0 && (
          <Box
            className="print--avoid-break"
            display="block"
            alignItems="center"
            flexDirection="column"
            height="5rem"
            justifyContent="center"
          >
            <Typography>No Checklist Information</Typography>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {readonly && noCardList ? (
        checkListGrid()
      ) : (
        <Card>
          <CardTitle
            title="Checklist Information"
            withExpand
            initialExpand={false}
            overrideExpand={isExpanded}
          >
            {checkListGrid()}
          </CardTitle>
        </Card>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  label: {
    '&& span': {
      fontSize: 14,
    },
  },
  print: {
    padding: theme.spacing(1),
  },
}));
