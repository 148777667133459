import { Autocomplete, Chip, TextField } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';
import { UserAvatar } from '..';
import { IListUser } from '../../models';
import clsx from 'clsx';
import { IColorSetMap } from '../../models/colors';

interface IUserFilterProps {
  className?: string;
  users: IListUser[];
  selectedUsers: IListUser[];
  onDelete: (user: IListUser) => unknown;
  onChange: (users: IListUser[]) => unknown;
  userColorMap?: IColorSetMap;
  isSmall?: boolean;
}

export const UserFilter: FC<IUserFilterProps> = ({
  className,
  users,
  selectedUsers,
  onDelete,
  onChange,
  userColorMap,
  isSmall,
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      fullWidth
      value={selectedUsers}
      multiple
      disableClearable
      className={clsx(classes.cardBody, className)}
      classes={{
        paper: classes.paperBorder,
      }}
      onChange={(_event, newValue: IListUser[]) => {
        if (newValue && newValue.length > 0) {
          onChange?.(newValue);
        } else {
          onChange?.([]);
        }
      }}
      options={users.filter(t => !selectedUsers.map(t => t.userId).includes(t.userId))}
      getOptionLabel={(option: IListUser) => {
        return option.userName;
      }}
      renderOption={(props, option: IListUser) => {
        return (
          <li {...props} key={option.userId}>
            {option.userName}
          </li>
        );
      }}
      renderTags={users =>
        users.map(user => (
          <Chip
            key={user.userId}
            avatar={
              <UserAvatar
                className={clsx(classes.userAvatar, 'print--none')}
                userName={user.userName}
              />
            }
            label={user.userName}
            variant="outlined"
            onDelete={() => onDelete?.(user)}
            className={classes.chip}
            classes={{ root: classes.chipRoot }}
            style={{ borderColor: userColorMap?.[user.userId]?.backgroundColor }}
          />
        ))
      }
      renderInput={params => {
        return (
          <TextField
            {...params}
            size={isSmall ? 'small' : undefined}
            fullWidth
            autoComplete="none"
            variant="outlined"
            label="Users"
            placeholder={selectedUsers.length ? '' : 'Select a user'}
          />
        );
      }}
    />
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  header: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  container: {
    padding: 0,
  },
  map: {
    marginTop: theme.spacing(4),
  },
  card: {},
  cardBody: {
    borderRadius: theme.spacing(0.5),
  },
  chip: {
    marginRight: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    borderColor: theme.palette.primary.main,
    borderRight: 'none',
    textOverflow: 'ellipsis',
    maxWidth: '175px',
  },
  chipRoot: {
    '& .MuiChip-deleteIcon': {
      fontSize: '30px',
      marginRight: '-14px',
      color: theme.palette.primary.main,
    },
  },
}));
