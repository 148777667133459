import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  alpha,
} from '@mui/material';
import {
  ICalendarView,
  IRouteUpdateMode,
  IService,
  IServiceChange,
  IServiceRoute,
  ITechOptimizationEvent,
  ITechnician,
} from '../../../models';
import { FC, Fragment, useContext, useMemo } from 'react';
import { createTechnicianDroppableId } from '../draggableUtils';
import { isRouteStartOrEnd } from '../utils';
import { ExpandMore } from '@mui/icons-material';
import { Droppable } from 'react-beautiful-dnd';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { InvalidLocationIcon } from '../../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { UserContext } from '../../../context';
import { NonOfficeItem } from './non-office-item';
import { PodServiceAccordionDetails } from './pod-service-accordion-details';

interface IPodServiceAccordion {
  tech: ITechnician;
  techIndex: number;
  routeIndex: number;
  podReadOnlyInCompareMode?: boolean;
  route: IServiceRoute;
  handleSiteChange: (id: string | number) => void;
  handleOptimizationClick: (e: any, tech: ITechnician) => void;
  allowOptimization: boolean;
  view: ICalendarView;
  isCondensed: boolean;
  saving?: boolean;
  toggleSelection?: (
    e: React.KeyboardEvent,
    podId: string,
    route: IService[],
    tech: ITechnician
  ) => void;
  selectedDraggableIds?: string[];
  activeDraggableId?: string | null;
  updateMode?: IRouteUpdateMode;
  changes?: Record<string, IServiceChange>;
  readonly?: boolean;
  allowMultiDrag?: boolean;
  showServiceIndex?: boolean;
  serviceIndexStyle?: 'avatar' | 'inline';
  onOptimizationClick?: (event: ITechOptimizationEvent) => unknown;
  colorizeSiteIndex?: boolean;
  serviceDate: string;
}

export const PodServiceAccordion: FC<IPodServiceAccordion> = ({
  tech,
  techIndex,
  routeIndex,
  podReadOnlyInCompareMode,
  route,
  handleOptimizationClick,
  allowOptimization,
  handleSiteChange,
  view,
  isCondensed,
  updateMode,
  changes,
  readonly,
  saving,
  toggleSelection,
  selectedDraggableIds,
  activeDraggableId,
  allowMultiDrag,
  showServiceIndex,
  serviceIndexStyle,
  colorizeSiteIndex,
  onOptimizationClick,
  serviceDate,
}) => {
  const { isSuperAdmin, isOfficeAdmin } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width: 1500px)');

  const totalServiceCount = useMemo(
    () => tech.services.filter(s => !isRouteStartOrEnd(s)).length,
    [tech]
  );
  const techDroppableId = useMemo(
    () =>
      createTechnicianDroppableId({
        routeId: route.routeId,
        userId: tech.userId,
        serviceDate: route.serviceDate,
        routeIndex,
        techIndex,
      }),
    [route.routeId, route.serviceDate, routeIndex, tech.userId, techIndex]
  );
  const hasInvalidLocations = useMemo(
    () => tech.services.some(service => !service?.latitude || !service?.longitude),
    [tech]
  );

  const hasUnsortedItems = useMemo(() => tech.services.some(service => !service.isSorted), [tech]);

  const classes = useStyles({ isCondensed });

  return (
    <Accordion
      disableGutters
      elevation={0}
      className={classes.accordionItem}
      slotProps={{ transition: { unmountOnExit: true } }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        className={classes.accordionHeader}
        sx={{
          padding: 1,
          border: theme => (tech.hasDayOff ? `1px solid ${theme.palette.error.main}` : ''),
        }}
        id={`${techIndex}-${tech.userId}-header`}
        aria-controls={`${techIndex}-${tech.userId}-content`}
      >
        {!isMobile && (
          <Avatar
            variant="circular"
            alt={tech.userName}
            src={`url(https://media.istockphoto.com/photos/smiling-business-man-picture-id136159540)`}
            sx={{
              width: '30px',
              height: '30px',
              marginRight: '12px',
              border: `2px solid ${tech?.color ? tech.color : '#aaaaaa'}`,
              backgroundColor: `${tech?.color ? tech.color : '#aaaaaa'}`,
              alignSelf: 'center',
            }}
          />
        )}
        <>
          <Box>
            <Tooltip title={tech.hasDayOff ? 'Tech has the day off' : ''}>
              <span>
                <Typography
                  sx={{
                    fontSize: '0.85rem',
                    overflowWrap: 'anywhere',
                    color: theme => (tech.hasDayOff ? theme.palette.error.main : ''),
                  }}
                >
                  {tech.userName}
                </Typography>
              </span>
            </Tooltip>
            <Typography variant="caption">
              {totalServiceCount} service{totalServiceCount > 1 ? 's' : ''}
            </Typography>
          </Box>
          <div className={classes.podIcons}>
            {hasInvalidLocations && <InvalidLocationIcon />}
            {hasUnsortedItems && (
              <Tooltip title="Contains unsorted items">
                <span>
                  <IconButton
                    disableRipple={allowOptimization}
                    onClick={e => {
                      handleOptimizationClick(e, tech);
                    }}
                    sx={{ padding: 0 }}
                    component="div"
                  >
                    <FontAwesomeIcon
                      className={classes.unsortedIcon}
                      icon={faExclamationCircle}
                      size="lg"
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
        </>
      </AccordionSummary>
      <AccordionDetails
        id={`${techIndex}-${tech.userId}-content`}
        aria-labelledby={`${techIndex}-${tech.userId}-header`}
        className={classes.accordionBody}
      >
        {(isOfficeAdmin || isSuperAdmin) && (
          <Droppable
            key={techDroppableId}
            droppableId={techDroppableId}
            type="droppableItem"
            isDropDisabled={podReadOnlyInCompareMode}
          >
            {provided => (
              <div
                className={clsx(classes.droppableArea)}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {tech.services.map((service, serviceIndex) => {
                  return (
                    <Fragment key={`${service.scheduledServiceId}-${serviceIndex}`}>
                      <PodServiceAccordionDetails
                        service={service}
                        routeIndex={routeIndex}
                        techIndex={techIndex}
                        serviceIndex={serviceIndex}
                        route={route}
                        tech={tech}
                        isCondensed={isCondensed}
                        updateMode={updateMode}
                        serviceDate={serviceDate}
                        changes={changes}
                        readonly={readonly}
                        selectedDraggableIds={selectedDraggableIds}
                        activeDraggableId={activeDraggableId}
                        toggleSelection={toggleSelection}
                        isShowingServiceIndex={showServiceIndex ?? false}
                        serviceIndexStyle={serviceIndexStyle}
                        colorizeSiteIndex={colorizeSiteIndex}
                        allowMultiDrag={allowMultiDrag}
                        saving={saving ?? false}
                        onOptimizationClick={onOptimizationClick}
                        podReadOnlyInCompareMode={podReadOnlyInCompareMode ?? false}
                        allowOptimization={allowOptimization}
                        view={view}
                        handleSiteChange={handleSiteChange}
                      />
                    </Fragment>
                  );
                })}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {!isOfficeAdmin && !isSuperAdmin && (
          <div>
            {tech.services.map(service => {
              return (
                <div key={service.scheduledServiceId}>
                  <NonOfficeItem
                    allowOptimization={allowOptimization}
                    tech={tech}
                    routeId={route.routeId}
                    handleOptimizationClick={handleOptimizationClick}
                    handleSiteChange={handleSiteChange}
                    service={service}
                    view={view}
                  />
                </div>
              );
            })}
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles<Theme, { isCondensed: boolean }>(theme => ({
  accordionItem: {
    position: 'relative',
    backgroundColor: 'white',
    '&.Mui-expanded': { backgroundColor: theme.palette.grey[100] },
    border: `1px solid ${theme.palette.dividers.grey}`,
    marginBottom: '-1px',
    '&:first-of-type': {
      borderRadius: `${theme.shape.borderRadius} 0`,
    },
    '&:last-of-type': {
      borderRadius: `0 ${theme.shape.borderRadius}`,
      marginBottom: 0,
    },
    '&&::before': {
      display: 'none', //hide border
    },
    // Bug Fix for height issue. Needs definitive height in order for dnd to work, so force display instead of height/overflow for MuiCollapse
    '&& .MuiCollapse-root': {
      display: 'none',
      height: 'auto',
      overflow: 'unset',
      minHeight: '100px',
    },
    '&.Mui-expanded .MuiCollapse-root': {
      display: 'block',
    },
    // end Bug Fix
  },
  accordionHeader: {
    fontSize: ({ isCondensed }: { isCondensed: boolean }) => (isCondensed ? '.8rem' : undefined),
    alignItems: 'center',
    '.Mui-expanded &&': {
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
      color: theme.palette.primary.main,
      '& .MuiAccordionSummary-expandIconWrapper': { color: theme.palette.primary.main },
    },
    '&& .MuiAccordionSummary-content': { margin: 0 },
    '&&:focus, &&:active': {
      outline: `3px solid ${alpha(theme.palette.primary.light, 0.5)}`,
      zIndex: 3,
    },
    '@media print': {
      'page-break-inside': 'avoid',
    },
  },
  accordionBody: {
    padding: '5px',
    '@media print': {
      'page-break-inside': 'avoid',
    },
  },
  podIcons: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 2px',
    flex: '1 1 auto',
    justifyContent: ' flex-end',
    gap: '2px',
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  unsortedIcon: {
    borderRadius: '50%',
    color: theme.palette.warning.main,
  },
  droppableArea: {
    minHeight: '65px',
    transitionDuration: '0ms', //seems to speed things up a bit when initiating drag
  },
}));
