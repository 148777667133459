import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Box, Alert, Button, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { Close } from '@mui/icons-material';
import { FC, useState } from 'react';

interface IUnsortStopsAlert {
  onOptimizeSwitch?: () => void;
}

export const UnsortedStopsAlert: FC<IUnsortStopsAlert> = ({ onOptimizeSwitch }) => {
  const classes = useStyles();
  const [dismissedUnsortedItemsWarning, setDismissedUnsortedItemsWarning] = useState(false);

  if (dismissedUnsortedItemsWarning) {
    return null;
  }
  return (
    <Box mt={1} mb={1}>
      <Alert severity="error" className={classes.optimizeAlert}>
        <Box className={classes.optimizeAlert}>
          <Box display="flex" flex="1 1 auto" gap={1} alignItems="center" flexWrap="wrap">
            <Typography fontWeight={600}>
              Unsorted stops exist - Please optimize these routes!
            </Typography>
            <Button
              size="small"
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faRoute} size="lg" />}
              onClick={onOptimizeSwitch}
              sx={{ width: { xs: '100%', sm: 'auto' } }}
            >
              Optimize
            </Button>
          </Box>
          <div>
            <IconButton onClick={() => setDismissedUnsortedItemsWarning(true)} size="small">
              <Close />
            </IconButton>
          </div>
        </Box>
      </Alert>
    </Box>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  optimizeAlert: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '0 8px',

    '& .MuiAlert-message': {
      flex: '1 1 auto',
    },
  },
}));
