import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Grid,
  FormHelperText,
  Box,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { FormikErrors, FormikTouched } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { CardTitle, Card, DateTimePicker } from '../../components';
import { getSingleTranCode } from '../../fetch';
import { formatMoney } from '../../helpers';
import { IListUser, IServiceDefinition } from '../../models';
import { useUnload } from '../../hooks';

interface IBillingCommissionInfo {
  handleBlur: any;
  errors: FormikErrors<any>;
  touched: FormikTouched<any>;
  values: any;
  isLoadingUsers: boolean;
  users: IListUser[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  dirty: boolean;
  isExpanded?: boolean;
  serviceDefinitions: IServiceDefinition[];
  isRecurring: boolean;
}
export const BillingCommissionInfo: FC<IBillingCommissionInfo> = ({
  errors,
  touched,
  values,
  handleBlur,
  isLoadingUsers,
  users,
  setFieldValue,
  dirty,
  isExpanded,
  isRecurring,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [tranCode, setTranCode] = useState<string>('');

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, dirty);

  const fetchTranCodes = async () => {
    try {
      const res = await getSingleTranCode(values.transactionId);
      setTranCode(res.code);
    } catch (error) {
      enqueueSnackbar(`Error loading transaction code, please try again.`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (values.transactionId) {
      fetchTranCodes();
    }

    if (values.transactionId === null) {
      setTranCode('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.transactionId]);

  return (
    <Card className={clsx('print--avoid-break', classes.card)}>
      <CardTitle
        title="Billing/Commission Information"
        withExpand
        initialExpand={false}
        overrideExpand={isExpanded}
        shouldNoWrap={false}
      >
        <Box mt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Service Amount"
                placeholder={'Service Amount'}
                name={'serviceAmount'}
                disabled={!values.override}
                value={values.serviceAmount}
                size="small"
                onBlur={e => {
                  setFieldValue('serviceAmount', formatMoney(e.target.value, 2));
                }}
                onChange={e => {
                  setFieldValue('serviceAmount', e.target.value);
                }}
                error={!!errors.serviceAmount && !!touched.serviceAmount}
              />
              {errors.serviceAmount && touched.serviceAmount && (
                <FormHelperText className={classes.error}>{errors.serviceAmount}</FormHelperText>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.override}
                    onChange={e => {
                      setFieldValue('override', !values.override);
                    }}
                  />
                }
                classes={{
                  root: classes.label,
                }}
                label={isRecurring ? 'Manually Edit the Service Amount' : 'Override'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimePicker
                label="Date Created"
                value={values.dateCreated}
                onChange={(date: any) => {
                  setFieldValue('dateCreated', date);
                }}
                disabled
                error={!!errors.status}
                textFieldClass={classes.dateCreated}
              />
              {errors.dateCreated && touched.dateCreated && (
                <FormHelperText className={classes.error}>{errors.dateCreated}</FormHelperText>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                placeholder={'Transaction Code'}
                label={'Transaction Code'}
                name={'transactionCode'}
                InputProps={{
                  readOnly: true,
                }}
                disabled
                size="small"
                value={tranCode}
                error={!!errors.transactionCode && !!touched.transactionCode}
                helperText={errors.createdBy && touched.createdBy && errors.createdBy}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Created By"
                select
                size="small"
                onChange={(e: any) => {
                  setFieldValue('createdBy', e.target.value);
                }}
                disabled
                onBlur={handleBlur}
                value={values.createdBy}
              >
                <MenuItem value={undefined}>Service</MenuItem>
                {!isLoadingUsers &&
                  users.map(user => (
                    <MenuItem key={`key-${user.userId}`} value={user.userId}>
                      {user.userName}
                    </MenuItem>
                  ))}
              </TextField>
              {errors.createdBy && touched.createdBy && (
                <FormHelperText className={classes.error}>{errors.createdBy}</FormHelperText>
              )}
            </Grid>
          </Grid>
        </Box>
      </CardTitle>
    </Card>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  card: { marginBottom: theme.spacing(2), '@media print': { marginBottom: '3rem' } },
  error: { color: theme.palette.error.main },
  dateCreated: {
    '& .MuiInputBase-root': {
      paddingBottom: '2px',
    },
  },
  label: {
    '&& span': {
      fontSize: 14,
    },
  },
}));
