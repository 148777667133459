import { Box, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { differenceInDays, endOfDay, startOfDay } from 'date-fns';
import { FC } from 'react';
import { IFilterInputProps } from '../../../../models';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { isValidDate } from '../../../../helpers';

export const DateRangeFilter: FC<IFilterInputProps> = ({ filter, values, onChange, filters }) => {
  const classes = useStyles();
  const { name, label } = filter;

  const [startDate = '', endDate = ''] = values;

  const hasMultipleDateRanges =
    (filters && filters?.filter(f => f.type === 'DateRange')?.length > 1) ?? false;

  const isSameDate = (start: string, end: string) => {
    const diff = differenceInDays(new Date(end), new Date(start));
    return diff === 0;
  };

  const handleStartDateChange = (date: Date | null) => {
    if (isValidDate(date)) {
      const value = date ? date.toISOString() : '';
      if (isSameDate(value, endDate)) {
        onChange(
          [startOfDay(new Date(value)).toISOString(), endOfDay(new Date(value)).toISOString()],
          filter
        );
      } else {
        onChange([value, endDate], filter);
      }
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (isValidDate(date)) {
      const value = date ? date.toISOString() : '';

      if (isSameDate(startDate, value)) {
        onChange(
          [startOfDay(new Date(value)).toISOString(), endOfDay(new Date(value)).toISOString()],
          filter
        );
      } else {
        onChange([startDate, value], filter);
      }
    }
  };

  let minEndDate: Date | undefined = undefined;

  let invalidRange = false;

  if (startDate && endDate) {
    const diff = differenceInDays(new Date(endDate), new Date(startDate));
    invalidRange = diff < 0;
  }

  return (
    <Box>
      <Box className={classes.datePickerSection}>
        <DatePicker
          label={hasMultipleDateRanges ? `${label} Start Date` : `Start Date`}
          format="MM/dd/yyyy"
          onChange={handleStartDateChange}
          value={startDate ? new Date(startDate) : null}
          slotProps={{
            textField: {
              name,
              size: 'small',
              error: invalidRange,
              InputLabelProps: { shrink: true },
              fullWidth: true,
            },
          }}
        />
        <Box>to</Box>
        <DatePicker
          label={hasMultipleDateRanges ? `${label} End Date` : `End Date`}
          minDate={minEndDate}
          format="MM/dd/yyyy"
          onChange={handleEndDateChange}
          value={endDate ? new Date(endDate) : null}
          slotProps={{
            textField: {
              name,
              size: 'small',
              error: invalidRange,
              InputLabelProps: { shrink: true },
              fullWidth: true,
            },
          }}
        />
      </Box>
      {invalidRange && (
        <FormHelperText error style={{ marginLeft: 0 }}>
          Invalid Date Range. Start Date must be after End Date.
        </FormHelperText>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  datePickerSection: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
}));
