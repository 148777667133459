import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FC, useState } from 'react';
import { ICalendarView, ICalendarViewOption } from '../../models';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';

interface WeekModeSelectProps {
  value: ICalendarView | undefined;
  onChange?: (value: ICalendarView) => unknown;
  disabled?: boolean;
}

export const WeekModeSelect: FC<WeekModeSelectProps> = ({
  value = ICalendarView.WorkWeek,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const updateModes = [
    {
      value: ICalendarView.Week,
      label: 'Week',
    },
    {
      value: ICalendarView.WorkWeek,
      label: 'Work Week',
    },
    {
      value: ICalendarView.DateRange,
      label: 'Date Range',
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelection = (selected: ICalendarViewOption) => {
    handleClose();
    if (selected.value === value) {
      return;
    }
    onChange?.(selected.value);
  };

  let selectedLabel = 'Week Mode';

  const selectedOption = updateModes.find(m => m.value === value);

  if (selectedOption) {
    selectedLabel = `${selectedOption.label}`;
  }

  return (
    <>
      <Button
        id="week-mode-select"
        aria-controls={open ? 'week-mode-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        variant="contained"
        size="large"
        className={classes.weekModeButton}
        disabled={disabled}
      >
        <ViewWeekIcon />
        {selectedLabel}
      </Button>
      <Menu
        id="week-mode-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'update-mode-select',
        }}
      >
        {updateModes.map(option => (
          <MenuItem
            key={option.value}
            selected={option === selectedOption}
            onClick={() => handleSelection(option)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  weekModeButton: {
    padding: '5px 16px',
    gap: theme.spacing(1),
  },
}));
