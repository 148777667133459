import { Box, Button, MenuItem, TextField } from '@mui/material';
import { useState, useEffect, forwardRef, FC } from 'react';
import { ICustomCustomerView } from '../../models';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { getCustomViews } from '../../fetch';
import { alphaSort, getLocalStorage } from '../../helpers';
import { Link } from '../../components';
import { LSKeys } from '../../constants';
import { useSnackbar } from 'notistack';

interface ICustomViewsWrapperProps {
  customViewSelected: string;
  setCustomViewSelected: (val: string) => void;
  handleCustomViewChange: () => void;
}

export const CustomViewsWrapper: FC<ICustomViewsWrapperProps> = ({
  customViewSelected,
  setCustomViewSelected,
  handleCustomViewChange,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingCustomViews, setLoadingCustomViews] = useState(true);
  const [customViews, setCustomViews] = useState<ICustomCustomerView[]>([]);
  const fetchCustomViews = async () => {
    try {
      const data = await getCustomViews();
      const userSelectedView = await getLocalStorage(LSKeys.CUSTOM_VIEW);
      setCustomViews(alphaSort(data, 'customViewDescription'));
      const selected = data.find(d => d.customViewDescription === userSelectedView);
      setCustomViewSelected(selected?.customViewId ?? data[0].customViewId);
    } catch (error) {
      enqueueSnackbar(`Error loading custom views, please try again.`, {
        variant: 'error',
      });
    } finally {
      setLoadingCustomViews(false);
    }
  };
  useEffect(() => {
    fetchCustomViews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      display="flex"
      sx={{ flex: 1, flexWrap: 'wrap' }}
      gap={2}
      flexDirection={{
        xs: 'column',
        sm: 'row',
      }}
      width={{
        xs: '100%',
        sm: 'auto',
      }}
    >
      {!isLoadingCustomViews && customViews?.length > 0 && (
        <>
          <TextField
            select
            label="Custom View"
            size="small"
            sx={{
              xs: '100%',
              sm: 'auto',
            }}
            onChange={({ target: { value } }) => {
              setCustomViewSelected(value);
              handleCustomViewChange();
            }}
            value={customViewSelected}
            disabled={isLoadingCustomViews}
          >
            {customViews?.map((view, index) => {
              return (
                <MenuItem key={`${index}`} value={view.customViewId}>
                  {view.customViewDescription}
                </MenuItem>
              );
            })}
          </TextField>
          <Box
            display="flex"
            gap={2}
            flexDirection={{
              xs: 'column',
              sm: 'row',
            }}
            alignItems="center"
          >
            <Button
              to={`/customers/view/${customViewSelected}`}
              color="secondary"
              size="small"
              startIcon={<FontAwesomeIcon icon={faEdit} />}
              component={forwardRef((props: any, _ref) => {
                return <Link {...props} type="white" />;
              })}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              Edit View
            </Button>
            <Button
              to="/customers/view/new"
              color="primary"
              size="small"
              startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              component={forwardRef((props: any, _ref) => {
                return <Link {...props} type="white" />;
              })}
              sx={{
                width: {
                  xs: '100%',
                  sm: 'auto',
                },
              }}
            >
              Create New View
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};
