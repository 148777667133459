import { Box, ListItem, ListItemButton, Tooltip, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  ServerSideDataGrid,
  useDataGrid,
  GridDataFetcher,
  Card,
  CardFiltersLayout,
  CardTitle,
  Link,
  TableActionsMenu,
  Tabs,
  ITab,
} from '../../../components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { faEdit, faEye, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useContext, useMemo, useState, useCallback } from 'react';
import { getTasks } from '../../../fetch';
import {
  formatDate,
  truncate,
  hasCorrectUserPermissions,
  hyphenSeparateTwoInputs,
  formatRawDate,
} from '../../../helpers';
import { ISimpleDateRange, ITask, IAccountDetail, IDateRange } from '../../../models';
import { faFilter, faFilterCircleXmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TasksFilters } from './tasks-filters';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../context';
import { Permissions } from '../../../constants';
import { QuickCompleteModal } from '.';

export interface ITasksProps {
  dateRange?: ISimpleDateRange;
  cardTitle: string;
  showFilters?: boolean;
  accountId?: string;
  currentCustomer?: IAccountDetail | null;
  redirect: string;
  isEditable?: boolean;
  excludeFilters?: string[];
  defaultUserFilter?: string;
  withExpand?: boolean;
  initialExpand?: boolean;
  overrideExpand?: boolean;
  addTaskButtonText?: string;
  scheduledServiceId?: string;
  siteId?: string;
}

export const Tasks: FC<ITasksProps> = ({
  dateRange,
  cardTitle,
  showFilters = false,
  accountId,
  currentCustomer,
  redirect,
  isEditable = true,
  excludeFilters,
  defaultUserFilter,
  withExpand,
  initialExpand = true,
  overrideExpand,
  addTaskButtonText,
  scheduledServiceId,
  siteId,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const { user } = useContext(UserContext);
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<string>('');
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: null,
    endDate: null,
    key: 'selection',
    inputValue: '',
  });
  const [selectedUserId, setSelectedUserId] = useState<string>(defaultUserFilter ?? '');
  const [selectedPriority, setSelectedPriority] = useState<string>('');
  const [filters, setFilters] = useState<{
    startDate?: string | null;
    endDate?: string | null;
    accountId?: string;
    taskDefinitionId?: string;
    isComplete?: boolean;
    userId?: string | null;
    priority?: string | null;
  } | null>(defaultUserFilter ? { userId: defaultUserFilter } : null);
  const [hasAppliedFilters, setHasAppliedFilters] = useState(!!defaultUserFilter);
  const [selectedTab, setSelectedTab] = useState<string>('O');
  const [selectedTaskDefId, setSelectedTaskDefId] = useState<string>('');

  const handleAddTaskRouting = () => {
    // If tasks is on customer page and adding a new task
    if (currentCustomer) {
      return history.push(
        `/scheduling/tasks/new${
          currentCustomer
            ? `?accountId=${currentCustomer?.accountId}&redirect=${redirect}`
            : `?redirect=${redirect}`
        }`
      );
    }
    // If on scheduled service page and adding a new task
    if (scheduledServiceId) {
      return history.push(
        `/scheduling/tasks/new${
          redirect ? `?redirect=${redirect}` : ''
        }&scheduledServiceId=${scheduledServiceId}
        ${accountId ? `&accountId=${accountId}` : ''}${siteId ? `&siteId=${siteId}` : ''}`
      );
    }
    return history.push(`/scheduling/tasks/new`);
  };

  const dataFetcher: GridDataFetcher<ITask> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      const filterStartDate = filters?.startDate && formatRawDate(new Date(filters?.startDate));
      const passedInStartDate =
        dateRange?.selection?.startDate && formatRawDate(new Date(dateRange?.selection?.startDate));

      const filterEndDate = filters?.endDate && formatRawDate(new Date(filters?.endDate));
      const passedInEndDate =
        dateRange?.selection?.endDate && formatRawDate(new Date(dateRange?.selection?.endDate));

      let params: any = {
        sortBy:
          sortColumn === 'account'
            ? 'AccountName'
            : sortColumn === 'taskDate'
            ? 'WhenScheduled'
            : sortColumn,
        sortDirection: sortDirection ? sortDirection : selectedTab === 'O' ? 'asc' : 'desc',
        page: page + 1,
        perPage,
        isComplete: selectedTab === 'C',
        accountId: accountId ?? selectedAccountId,
        userId: filters?.userId ?? defaultUserFilter,
        priority: filters?.priority,
        taskDefinitionId: filters?.taskDefinitionId,
        scheduledServiceId: scheduledServiceId ?? null,
      };
      if (selectedTab === 'O') {
        params.includeOpen = true;
        params.whenScheduledStartDate = passedInStartDate ?? filterStartDate;
        params.whenScheduledEndDate = passedInEndDate ?? filterEndDate;
      }
      if (selectedTab === 'C') {
        params.includeOpen = false;
        params.whenCompletedStartDate = passedInStartDate ?? filterStartDate;
        params.whenCompletedEndDate = passedInEndDate ?? filterEndDate;
      }
      try {
        const res = await getTasks(params);

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading tasks, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, selectedTab, dateRange]
  );

  const {
    rows: items,
    isLoading: isLoadingTasks,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    setPage,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch: refetchTasks,
    setSortDirection,
  } = useDataGrid<ITask>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'tasks-grid',
      sortColumn: 'taskDate',
      sortDirection: selectedTab === 'O' ? 'asc' : 'desc',
    },
    dataFetcher,
  });

  const columns = useMemo(
    () =>
      [
        {
          field: 'taskDate',
          headerName: 'Date',
          minWidth: 100,
          maxWidth: 100,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return (
              <Link
                to={`/scheduling/tasks/${original.scheduledTaskId}${
                  currentCustomer || redirect ? '?' : ''
                }${currentCustomer ? `accountId=${currentCustomer?.accountId}&` : ''}${
                  redirect ? `redirect=${redirect}` : ''
                }`}
              >
                <Tooltip title="Edit Task" placement="bottom">
                  <span>
                    {original.taskDate ? formatDate(original.taskDate?.split?.('T')?.[0]) : ''}
                  </span>
                </Tooltip>
              </Link>
            );
          },
        },
        !currentCustomer && {
          field: 'account',
          headerName: 'Customer',
          flex: 1,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            if (hasCorrectUserPermissions(Permissions.ViewCustomers, user!)) {
              return (
                <Link to={`/customers/${original?.accountId}`}>
                  <Tooltip placement="bottom" title="View Customer">
                    <span>{original.accountName}</span>
                  </Tooltip>
                </Link>
              );
            } else {
              return <span>{original.accountName}</span>;
            }
          },
        },
        selectedTab === 'O' && {
          field: 'priority',
          headerName: 'Priority',
          flex: 1,
          minWidth: 100,
        },
        {
          headerName: 'Task Type',
          field: 'taskType',
          flex: 1,
          minWidth: 100,
        },
        {
          headerName: 'Assigned To',
          field: 'assignedTo',
          flex: 1,
          minWidth: 150,
        },
        selectedTab === 'C' && {
          headerName: 'Completed Date',
          field: 'whenCompleted',
          flex: 1,
          minWidth: 100,
          maxWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return original.whenCompleted && <>{formatDate(original.whenCompleted)}</>;
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return original.description ? (
              <Tooltip
                key={`${original.scheduledTaskId}-description`}
                placement="bottom"
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: original.description,
                    }}
                  />
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      String(original.description).replace(/<\/?[^>]+(>|$)/g, '') ?? '',
                      60
                    ),
                  }}
                />
              </Tooltip>
            ) : (
              ''
            );
          },
        },
        selectedTab === 'C' && {
          headerName: 'Completion Notes',
          field: 'notes',
          flex: 1.5,
          minWidth: 150,
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return original.notes ? (
              <Tooltip
                placement="bottom"
                title={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: original.notes,
                    }}
                  />
                }
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncate(
                      String(original.notes).replace(/<\/?[^>]+(>|$)/g, '') ?? '',
                      60
                    ),
                  }}
                />
              </Tooltip>
            ) : (
              ''
            );
          },
        },
        {
          headerName: '',
          field: 'actions',
          flex: 1,
          minWidth: 40,
          maxWidth: 150,
          align: 'center',
          renderCell: (params: GridRenderCellParams<ITask>) => {
            const { row: original } = params;
            return (
              <TableActionsMenu labelContext="Task" id={`action-menu-${original.scheduledTaskId}`}>
                {isEditable && (
                  <Link
                    to={`/scheduling/tasks/${original.scheduledTaskId}${
                      currentCustomer || redirect ? '?' : ''
                    }${currentCustomer ? `accountId=${currentCustomer?.accountId}&` : ''}${
                      redirect ? `redirect=${redirect}` : ''
                    }`}
                  >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <FontAwesomeIcon icon={faEdit} style={{ marginRight: '.5rem' }} />
                        Edit Task
                      </ListItemButton>
                    </ListItem>
                  </Link>
                )}
                {!currentCustomer &&
                  original.accountId &&
                  hasCorrectUserPermissions(Permissions.ViewCustomers, user!) && (
                    <Link to={`/customers/${original?.accountId}`}>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <FontAwesomeIcon icon={faEye} style={{ marginRight: '.5rem' }} />
                          View Customer
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  )}
                {isEditable && !original.whenCompleted && (
                  <ListItem disablePadding sx={{ color: theme => theme.palette.primary.main }}>
                    <ListItemButton
                      color="primary"
                      onClick={() => {
                        setSelectedTask(original);
                        setCompleteModalIsOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faCheck} style={{ marginRight: '.5rem' }} />
                      Quick Complete
                    </ListItemButton>
                  </ListItem>
                )}
              </TableActionsMenu>
            );
          },
        },
      ].filter(Boolean) as any[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, isEditable, selectedTab]
  );

  return (
    <>
      <Card>
        <CardTitle
          title={cardTitle}
          mobileWrap
          action={
            <>
              {showFilters && (
                <Button
                  onClick={() => setIsShowingFilters(!isShowingFilters)}
                  className="print--none"
                  size="small"
                  color="secondary"
                  disabled={isLoadingTasks}
                  startIcon={
                    <FontAwesomeIcon icon={isShowingFilters ? faFilterCircleXmark : faFilter} />
                  }
                >
                  Filters
                </Button>
              )}
              <Button
                onClick={() => handleAddTaskRouting()}
                color="secondary"
                size="small"
                disabled={isLoadingTasks}
                startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
              >
                {addTaskButtonText ?? 'Add Task'}
              </Button>
            </>
          }
          withExpand={withExpand}
          initialExpand={initialExpand}
          overrideExpand={overrideExpand}
          marginBottom={0}
        >
          <CardFiltersLayout isOpen={isShowingFilters}>
            <TasksFilters
              showCustomerFilters={!!currentCustomer}
              setSelectedAccountId={setSelectedAccountId}
              setSelectedDateRange={setSelectedDateRange}
              selectedDateRange={selectedDateRange}
              isLoading={isLoadingTasks}
              setHasAppliedFilters={setHasAppliedFilters}
              hasAppliedFilters={hasAppliedFilters}
              showPriorityFilter={selectedTab === 'O'}
              setSelectedTaskDefId={setSelectedTaskDefId}
              selectedTaskDefId={selectedTaskDefId}
              selectedUserId={selectedUserId}
              setSelectedUserId={setSelectedUserId}
              selectedPriority={selectedPriority}
              setSelectedPriority={setSelectedPriority}
              excludeFilters={excludeFilters}
              applyFilters={(clearFilters?: boolean) => {
                if (clearFilters) {
                  setFilters({
                    startDate: null,
                    endDate: null,
                    accountId: '',
                    taskDefinitionId: '',
                    isComplete: false,
                    userId: '',
                    priority: '',
                  });
                } else {
                  setPage(0);
                  setFilters({
                    ...filters,
                    startDate: selectedDateRange?.startDate?.toISOString(),
                    endDate: selectedDateRange?.endDate?.toISOString(),
                    accountId: selectedAccountId,
                    taskDefinitionId: selectedTaskDefId,
                    userId: selectedUserId,
                    priority: selectedPriority,
                  });
                }
              }}
            />
          </CardFiltersLayout>
          {selectedTab && (
            <Box>
              <Tabs
                id="tasks-tabs"
                selectedTab={selectedTab}
                setSelectedTab={async val => {
                  // Reset upon tab switch
                  onPageChange(0);
                  setSelectedPriority('');
                  setFilters({
                    ...filters,
                    priority: '',
                  });
                  setSortDirection(val === 'O' ? 'asc' : 'desc');
                  setSelectedTab(val);
                }}
                tabs={
                  [
                    {
                      key: 'O',
                      title: 'Open',
                      disabled: isLoadingTasks,
                    },
                    {
                      key: 'C',
                      title: 'Closed',
                      disabled: isLoadingTasks,
                    },
                  ].filter(Boolean) as ITab[]
                }
              />
            </Box>
          )}
          <ServerSideDataGrid
            autoHeight
            getRowId={(row: ITask) => row.scheduledTaskId}
            rows={items}
            columns={columns}
            loading={isLoadingTasks}
            rowCount={recordCount}
            page={page}
            pageSize={perPage}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            sortModel={sortModel}
            onSortModelChange={onSortModelChange}
            disableColumnFilter
            noResultsMessage="No Scheduled Tasks."
            hasMobileLayout
            mobileProps={{
              mobileCustomDefaultAccessor: (val: ITask) =>
                hyphenSeparateTwoInputs(val?.accountName, val?.taskType),
              truncateAccordionLabel: true,
            }}
          />
        </CardTitle>
      </Card>
      {selectedTask && (
        <QuickCompleteModal
          open={completeModalIsOpen}
          onClose={() => setCompleteModalIsOpen(false)}
          task={selectedTask}
          onSubmit={() => {
            refetchTasks();
          }}
        />
      )}
    </>
  );
};
