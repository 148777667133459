import { faChevronLeft, faUndo, faRoute } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useMediaQuery, Button } from '@mui/material';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { SaveButton } from '../../components';
import { IRouteUpdateMode } from '../../models';
import { UpdateModeSelect } from '../routes';
import { useConfirm } from '../../hooks';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';

interface IServiceButtons {
  handleSave: () => void;
  isOfficeAdmin: boolean;
  isDisabled?: boolean;
  updateMode: IRouteUpdateMode;
  onUpdateModeChange?: (value: IRouteUpdateMode) => unknown;
  onReset?: () => unknown;
  isLoadingSelectedRoute?: boolean;
  confirmChangesLoss: () => Promise<boolean>;
  hasChanges: boolean;
  optimizeRoutes: () => Promise<void>;
}

export const ServiceButtons: FC<IServiceButtons> = ({
  updateMode,
  onUpdateModeChange,
  handleSave,
  isOfficeAdmin,
  isDisabled,
  onReset,
  isLoadingSelectedRoute,
  hasChanges,
  confirmChangesLoss,
  optimizeRoutes,
}) => {
  const isMobile = useMediaQuery(`(max-width: 1230px)`);
  const isMobileSm = useMediaQuery(`(max-width: 900px)`);
  const classes = useStyles({ isMobileSm });
  const history = useHistory();
  const confirm = useConfirm();
  const params = new URLSearchParams(window.location.search);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={isMobile ? 'flex-start' : 'flex-end'}
      marginTop={isMobile ? '1rem' : 0}
      gap={1}
      className={clsx('print--none', classes.mobileButtonStyles)}
    >
      <>
        <Button
          onClick={async () => {
            if (hasChanges) {
              const result = await confirmChangesLoss();
              if (!result) {
                return;
              }
            }
            history.push(params.get('redirect') ?? '/routes');
          }}
          color="inherit"
          startIcon={<FontAwesomeIcon icon={faChevronLeft} size="lg" />}
        >
          Back
        </Button>
        {isOfficeAdmin && (
          <>
            <UpdateModeSelect value={updateMode} onChange={onUpdateModeChange} />
            <Button
              disabled={isLoadingSelectedRoute}
              onClick={async () => {
                if (hasChanges) {
                  const result = await confirm(
                    'You have unsaved changes, are you sure you want to optimize?'
                  );
                  if (result) {
                    return optimizeRoutes();
                  } else {
                    return;
                  }
                }
                optimizeRoutes();
              }}
              color="primary"
              startIcon={<FontAwesomeIcon icon={faRoute} />}
            >
              Optimize
            </Button>
            <Button
              disabled={isDisabled}
              color="error"
              onClick={onReset}
              startIcon={<FontAwesomeIcon icon={faUndo} />}
            >
              Discard Changes
            </Button>
            <SaveButton disabled={isDisabled} handleSave={() => !!handleSave && handleSave()} />
          </>
        )}
      </>
    </Box>
  );
};

const useStyles = makeStyles<Theme, { isMobileSm: boolean }>((theme: Theme) => ({
  mobileButtonStyles: {
    width: ({ isMobileSm }) => (isMobileSm ? '100%' : 'auto'),
    flexDirection: ({ isMobileSm }) => (isMobileSm ? 'column' : 'row'),
    '& .MuiButton-root': {
      width: ({ isMobileSm }) => (isMobileSm ? '100%' : 'auto'),
    },
  },
}));
