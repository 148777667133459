import {
  Button,
  TextField as MuiTextField,
  Stack,
  Card,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { FC, useContext, useEffect, useState, useMemo } from 'react';
import * as Yup from 'yup';
import { ILookupModel, IOfficeInfo } from '../../../models';
import { UserContext } from '../../../context';
import {
  getOfficeInformation,
  updateOfficeInformation,
  getTimeZoneList,
  createOfficeDocuments,
  getAccountDocuments,
  deleteAccountDocument,
  updateAccountDocument,
} from '../../../fetch';
import {
  FloatingToolbar,
  SaveButton,
  EditAddressInformation,
  Documents,
  CardTitle,
  Select,
} from '../../../components';
import { AccountDetailsCol } from '../../customers/customers-detail/account-details-col';
import { CompanyPhoneTable } from './company-info-phone-table';
import { hasCorrectUserPermissions } from '../../../helpers';
import { Permissions, defaultUnsavedChangesMessage } from '../../../constants';
import { deepEqual } from 'fast-equals';
import { useConfirm } from '../../../hooks';

const CompanyInfoPageSchema = Yup.object().shape({
  code: Yup.string().required('Required').max(10),
  officeName: Yup.string()
    .required('Required')
    .max(100, 'Office Name should not exceed 100 characters'),
  adminEmail: Yup.string().max(50, 'Max 50 characters').email('Email address invalid'),
  emailFromAddress: Yup.string().max(50, 'Max 50 characters').email('Email address invalid'),
  timeZone: Yup.string().required('Required').max(50),
  street: Yup.string().required('Required').max(100, 'Street should not exceed 100 characters'),
  city: Yup.string().required('Required').max(35, 'City should not exceed 35 characters'),
  state: Yup.string().required('Required').max(10, 'state should not exceed 10 characters'),
  postalCode: Yup.string()
    .required('Required')
    .max(10, 'Postal Code should not exceed 10 characters'),
});

export const CompanyInfoTable: FC = () => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const { user, office } = useContext(UserContext);
  const [currentOffice, setCurrentOffice] = useState<IOfficeInfo | null>(null);
  const [timeZones, setTimeZones] = useState<ILookupModel[]>([]);
  const [isLoadingTimeZones, setIsLoadingTimeZones] = useState(false);
  const [isLoadingCompanyInfo, setIsLoadingCompanyInfo] = useState(false);
  const [pushPinLocation, setPushPinLocation] = useState<Microsoft.Maps.Location | null>(null);
  const [isSavingInformation, setIsSavingInformation] = useState(false);

  const hasPermissionToEditAccountCode = useMemo(
    () =>
      hasCorrectUserPermissions(Permissions.ChangeAccountCode, user!) && office?.canEditAccountCode,
    [office, user]
  );

  const fetchTimeZones = async () => {
    setIsLoadingTimeZones(true);
    try {
      const res = await getTimeZoneList();
      setTimeZones(res);
    } catch (error) {
      enqueueSnackbar(`Error loading time zone list, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsLoadingTimeZones(false);
    }
  };

  const fetchOfficeInformation = async () => {
    try {
      const res = await getOfficeInformation({ officeId: user?.officeId });
      setCurrentOffice(res);
      setIsLoadingCompanyInfo(true);
      setTimeout(() => {
        setIsLoadingCompanyInfo(false);
      }, 500);
    } catch (error) {
      enqueueSnackbar(`Error loading Company Information, please try again.`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    fetchTimeZones();
    fetchOfficeInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.officeId]);

  return (
    <Formik
      initialValues={{
        code: currentOffice?.code ?? '',
        officeName: currentOffice?.officeName ?? '',
        emailFromAddress: currentOffice?.emailFromAddress ?? '',
        phoneNumbers: currentOffice?.phoneNumbers ?? [],
        adminEmail: currentOffice?.adminEmail ?? '',
        canEditAccountCode: currentOffice?.canEditAccountCode ?? false,
        timeZone: currentOffice?.timeZone ?? '',
        officeId: currentOffice?.officeId ?? '',
        street: currentOffice?.street ?? '',
        city: currentOffice?.city ?? '',
        state: currentOffice?.state ?? '',
        postalCode: currentOffice?.postalCode ?? '',
        latitude: currentOffice?.latitude ?? 0,
        longitude: currentOffice?.longitude ?? 0,
        addressId: currentOffice?.officeId ?? '',
        addressName: currentOffice?.officeName,
        whenVerified: currentOffice?.whenVerified ?? null,
      }}
      enableReinitialize
      validationSchema={CompanyInfoPageSchema}
      onSubmit={async (values, actions) => {
        try {
          const data: IOfficeInfo = {
            officeId: user?.officeId || '',
            code: values.code,
            officeName: values.officeName ?? '',
            street: values.street ?? '',
            city: values.city ?? '',
            state: values.state ?? '',
            postalCode: values.postalCode ?? '',
            latitude: values.latitude ?? 0,
            longitude: values.longitude ?? 0,
            phoneNumbers: values.phoneNumbers || null,
            adminEmail: values.adminEmail || '',
            emailFromAddress: values?.emailFromAddress || '',
            canEditAccountCode: values.canEditAccountCode || false,
            timeZone: values.timeZone || '',
            addressId: values.addressId || '',
            whenVerified: values.whenVerified || null,
          };
          await updateOfficeInformation(data);
          enqueueSnackbar('Successfully Updated Office information!', {
            variant: 'success',
          });
          setIsSavingInformation(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error: any) {
          enqueueSnackbar(
            error?.Detail ? error?.Detail : 'An errror occurred. Please try again later.',
            {
              variant: 'error',
            }
          );
        }
      }}
    >
      {({
        resetForm,
        isSubmitting,
        values,
        initialValues,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
        dirty,
        isValid,
      }) => {
        const reset = async (resetForm: any) => {
          if (!deepEqual(initialValues, values)) {
            const result = await confirm(defaultUnsavedChangesMessage);
            if (result) {
              resetForm();
            } else {
              return;
            }
          } else {
            resetForm();
          }
        };
        return (
          <>
            <Form>
              <Card>
                <CardTitle
                  cardTitleClassName="customTitle"
                  title="Office Information"
                  withExpand
                  overrideExpand={true}
                  sx={{ padding: '1.0rem' }}
                >
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={12} sm={4} md={6}>
                      <MuiTextField
                        fullWidth
                        name="code"
                        label="Code"
                        required
                        size="small"
                        disabled={!hasPermissionToEditAccountCode}
                        error={errors?.code ? true : false}
                        helperText={errors && errors?.code}
                        value={values.code}
                        onBlur={e => {
                          handleBlur(e);
                        }}
                        onChange={e => {
                          setFieldValue('code', e.target.value);
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6}>
                      <TextField
                        fullWidth
                        name="officeName"
                        required
                        size="small"
                        label="Office Name"
                        value={values.officeName}
                        error={errors?.officeName ? true : false}
                        helperText={errors && errors?.officeName}
                        onBlur={e => {
                          handleBlur(e);
                        }}
                        onChange={e => {
                          setFieldValue('officeName', e.target.value);
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6}>
                      <TextField
                        fullWidth
                        name="adminEmail"
                        required
                        size="small"
                        label="Send Admin Email To"
                        value={values.adminEmail}
                        error={errors?.adminEmail ? true : false}
                        helperText={errors && errors?.adminEmail}
                        onBlur={e => {
                          handleBlur(e);
                        }}
                        onChange={e => {
                          setFieldValue('adminEmail', e.target.value, true);
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} md={6}>
                      <MuiTextField
                        fullWidth
                        name="emailFromAddress"
                        label="Show Email From As"
                        required
                        size="small"
                        value={values.emailFromAddress}
                        error={errors?.emailFromAddress ? true : false}
                        helperText={errors && errors?.emailFromAddress}
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('emailFromAddress', e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <AccountDetailsCol>
                        <Select
                          fullWidth
                          size="small"
                          autoComplete="nope"
                          label="Time Zone"
                          name="timeZone"
                          disabled={isLoadingTimeZones}
                          required
                          onChange={e => {
                            setFieldValue('timeZone', e.target.value, true);
                          }}
                        >
                          {timeZones.map(option => (
                            <MenuItem key={option.description} value={option.description}>
                              {option.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </AccountDetailsCol>
                      <Grid item xs={12} sm={4} md={6} hidden>
                        <MuiTextField
                          fullWidth
                          name="whenVerified"
                          size="small"
                          value={values.whenVerified}
                          onBlur={handleBlur}
                          onChange={e => {
                            setFieldValue('whenVerified', e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid padding={2}>
                    <EditAddressInformation
                      accountAddress={null}
                      setPushPinLocation={setPushPinLocation}
                      pushPinLocation={pushPinLocation}
                      addressNameLabel="Office Name"
                      showAccountAddressButton={false}
                      isOfficeAddress={true}
                    />
                  </Grid>
                </CardTitle>
              </Card>

              <Stack gap={2} marginTop={2}>
                <CompanyPhoneTable />
              </Stack>

              <Stack gap={2}>
                <Documents
                  gridKeyName="office-documents-grid"
                  labelContext="Office"
                  getApiRequest={getAccountDocuments}
                  deleteApiRequest={deleteAccountDocument}
                  postApiRequest={createOfficeDocuments}
                  putApiRequest={updateAccountDocument}
                  getFilters={{ siteId: null, accountId: null }}
                  postFilterId={user?.officeId!}
                  disableDelete={item => {
                    return false;
                  }}
                  isEditable={true}
                  marginTop={2}
                  isCollapsible
                  initialExpand
                  isExpanded={false}
                  hasWrapper={true}
                  hideOnEmpty
                />
              </Stack>
              <FloatingToolbar>
                <Button
                  color="inherit"
                  disabled={isLoadingCompanyInfo || isSubmitting}
                  onClick={() => reset(resetForm)}
                >
                  Cancel
                </Button>
                <SaveButton
                  disabled={
                    isSubmitting ||
                    isLoadingCompanyInfo ||
                    !dirty ||
                    !isValid ||
                    isSavingInformation
                  }
                />
              </FloatingToolbar>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
