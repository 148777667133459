import { CardTitle, Card, useDataGrid, GridDataFetcher } from '../../../components';
import { Box, Tooltip, Button, Typography, Grid } from '@mui/material';
import { useState, FC, useCallback } from 'react';
import { IAccountSimple, ITransaction } from '../../../models';
import { createRepairInvoice, getTransactions } from '../../../fetch';
import { useSnackbar } from 'notistack';
import clsx from 'clsx';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OTSInvoicesDataGrid } from './ots-invoices-data-grid';

interface IOTSInvoices {
  repairId: string;
  isExpanded?: boolean;
  shouldShowLaborModal?: boolean;
  enableCreateInvoice: boolean;
  isModal?: boolean;
  isOTSStatusOpen?: boolean;
  paymentMethodId: string | null;
  refetchRepairStatus: () => void;
  currentCustomer?: IAccountSimple | null;
  creditCardLast4Digits?: string;
  creditCardExpirationDate?: string;
}

export const OTSInvoices: FC<IOTSInvoices> = ({
  repairId,
  isExpanded,
  shouldShowLaborModal,
  enableCreateInvoice,
  isModal,
  isOTSStatusOpen,
  paymentMethodId,
  refetchRepairStatus,
  currentCustomer,
  creditCardLast4Digits,
  creditCardExpirationDate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const history = useHistory();
  const redirect = `?redirect=/services/ots/${repairId}`;

  const dataFetcher: GridDataFetcher<ITransaction> = useCallback(
    async ({ page, perPage, sortColumn, sortDirection }) => {
      try {
        const res = await getTransactions({
          repairId,
          sortBy: sortColumn || 'whenPosted',
          sortDirection: sortDirection || 'desc',
        });

        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(error?.Detail ?? `Error loading transactions, please try again.`, {
          variant: 'error',
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const {
    rows,
    isLoading,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
    refetch,
  } = useDataGrid<ITransaction>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: 'ots-invoices-grid',
    },
    dataFetcher,
  });

  const handleCreateInvoice = async () => {
    try {
      setIsSubmitting(true);
      const newTransactionId = await createRepairInvoice(repairId);
      enqueueSnackbar(`Invoice created!`, {
        variant: 'success',
      });
      history.push(`/billing/invoices/${newTransactionId}${redirect}`);
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error creating invoice, please try again`, {
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Card>
        <CardTitle
          title="Invoices"
          mobileWrap
          withExpand
          overrideExpand={isExpanded}
          action={
            !isModal ? (
              <Tooltip title={!enableCreateInvoice ? 'Agreement not signed' : ''}>
                <Grid container alignItems="center" spacing={2}>
                  {creditCardLast4Digits && creditCardExpirationDate && (
                    <Grid item>
                      <Typography style={{ fontSize: '18px', fontWeight: 'bold' }}>
                        <span style={{ color: '#99b2c6' }}>{`CC Last Four: `}</span>
                        <span style={{ color: '#333', fontSize: '16px', marginRight: '15px' }}>
                          {creditCardLast4Digits}
                        </span>
                        <span style={{ color: '#99b2c6' }}>{` Expires: `}</span>
                        <span style={{ color: '#333', fontSize: '16px' }}>
                          {creditCardExpirationDate}
                        </span>
                      </Typography>
                    </Grid>
                  )}

                  <Grid item>
                    <Button
                      onClick={e => {
                        handleCreateInvoice();
                      }}
                      className={clsx('print--none')}
                      color="secondary"
                      size="small"
                      disabled={
                        !enableCreateInvoice || isLoading || rows?.length === 1 || isSubmitting
                      }
                      startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
                    >
                      Create Invoice
                    </Button>
                  </Grid>
                </Grid>
              </Tooltip>
            ) : undefined
          }
        >
          <Box mt={1}>
            <OTSInvoicesDataGrid
              loading={isLoading}
              rows={rows}
              rowCount={recordCount}
              page={page}
              pageSize={perPage}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              sortModel={sortModel}
              onSortModelChange={onSortModelChange}
              refetch={refetch}
              redirect={redirect}
              hideFooter={true}
              isOTSStatusOpen={isOTSStatusOpen}
              repairId={repairId}
              paymentMethodId={paymentMethodId}
              refetchRepairStatus={refetchRepairStatus}
              shouldShowLaborModal={shouldShowLaborModal}
              currentCustomer={currentCustomer}
            />
          </Box>
        </CardTitle>
      </Card>
    </>
  );
};
