import { Box, Button, ButtonGroup } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Loader } from '../loader';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faRemove } from '@fortawesome/free-solid-svg-icons';

export const UploadedImage = ({
  source,
  isLoading,
  showZoom = true,
}: {
  source: string;
  isLoading?: boolean;
  showZoom?: boolean;
}) => {
  const classes = useStyles();
  const [zoomScale, setZoomScale] = useState<number>(1);

  return (
    <>
      {showZoom && (
        <ButtonGroup>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
            onClick={() => {
              setZoomScale(zoomScale / 0.8);
            }}
          >
            Zoom In
          </Button>
          <Button
            type="button"
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<FontAwesomeIcon icon={faRemove} />}
            onClick={() => {
              setZoomScale(zoomScale * 0.8);
            }}
          >
            Zoom Out
          </Button>
        </ButtonGroup>
      )}
      <Box display="flex" mt={2} justifyContent="center" className={classes.imgBox}>
        {isLoading && (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Loader type="overlay" />
          </Box>
        )}
        {source && (
          <div
            style={
              {
                overflow: `${zoomScale > 1 ?  'scroll' : 'hidden'}`,
                width: `${zoomScale > 1 ?  '100%' : '55%'}`,
                height: `100%`,
              }
            }
          >
            <img
              src={source}
              alt={'snap'}
              style={{width:`100%`,height:`100%`, objectFit:`cover`, transform: `scale(${zoomScale}, ${zoomScale})`,
            }}

            />
          </div>
        )}
      </Box>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  imgBox: {
    maxWidth: '100%',
    justifyItems: 'center',

  },
  img: {
    height: 'inherit',
    maxWidth: 'inherit',
    padding: theme.spacing(1),
  },
}));
