import axios from 'axios';
import { tokenRequest, msalInstance } from '../services';
import { unauthorizedRedirectUrl } from '../constants/auth';
import { getCookie, getEnvCookieKey } from './cookies';

let buildConfig = require('../buildSettings');
let CANCEL_TOKEN_SOURCE = axios.CancelToken.source();

export const generateNewCancelTokenSource = () => {
  CANCEL_TOKEN_SOURCE = axios.CancelToken.source();
};

const instance = axios.create({
  baseURL: `${buildConfig.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

instance.interceptors.request.use(async config => {
  config.cancelToken = CANCEL_TOKEN_SOURCE.token;
  try {
    const tokenRes = await msalInstance.acquireTokenSilent(tokenRequest);
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${tokenRes.accessToken}`,
      },
    };
  } catch (error) {}
  return config;
});

instance.interceptors.response.use(
  response => response,
  async error => {
    // if the error is a cancel then don't return any reject errors, but just resolve the promise
    if (axios.isCancel(error)) {
      return new Promise(() => {});
    }
    // for pinch service, if we get a 401 for whatever reason, and we have a cookie, then reject the promise
    // this will log the user out and send them to the login page
    if (error?.response?.status === 401 && getCookie(getEnvCookieKey())) {
      return Promise.reject(error);
    }

    // user gets a 401 while on a page after getting a request, redirct to /login or /unauthorized, also ignore for hitting /login endpoint
    if (error?.response?.status === 401 && !error?.response?.config?.url?.includes('/login')) {
      if (
        !window.history.state?.state ||
        window.history.state?.state?.from === window.location.pathname
      ) {
        if (
          window.location.pathname !== '/unauthorized' &&
          window.location.pathname !== '/logout'
        ) {
          const isPool360 = unauthorizedRedirectUrl.includes('/unauthorized');
          window.location.href = unauthorizedRedirectUrl;
          if (!isPool360 && !!msalInstance.getActiveAccount()) {
            try {
              // Log out of MSAL
              await msalInstance.logoutRedirect({
                postLogoutRedirectUri: `${buildConfig.REACT_APP_MSAL_REDIRECT}`,
              });
            } catch (error) {
              console.error(error);
            }
          }
        } else {
          return Promise.reject(error);
        }
      }
      return new Promise(() => {});
    }
    return Promise.reject(error);
  }
);

export const finishPendingRequests = (cancellationReason: string) => {
  CANCEL_TOKEN_SOURCE.cancel(cancellationReason);
  generateNewCancelTokenSource();
};

export { instance };
