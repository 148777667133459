import { Grid, Button, Box } from '@mui/material';
import { FC } from 'react';

interface IFilterButtons {
  isDisabled?: boolean;
  handleApplyFilters: () => void;
  handleResetFilters: () => void;
  hasAppliedFilters: boolean;
}

export const FilterButtons: FC<IFilterButtons> = ({
  isDisabled,
  handleApplyFilters,
  handleResetFilters,
  hasAppliedFilters,
}) => {
  return (
    <Grid item xs={12}>
      <Box gap={1} display="flex" justifyContent="flex-end">
        {hasAppliedFilters && (
          <Button
            size="small"
            color="inherit"
            disabled={isDisabled}
            onClick={() => {
              handleResetFilters();
            }}
          >
            Reset
          </Button>
        )}
        <Button
          color="primary"
          size="small"
          disabled={isDisabled}
          onClick={() => {
            handleApplyFilters();
          }}
        >
          Apply Filters
        </Button>
      </Box>
    </Grid>
  );
};
