import { faRoute, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  styled,
  Typography,
  LinearProgress,
  linearProgressClasses,
  Button,
  Grid,
} from '@mui/material';
import { ServiceRoutesContext, UserContext } from '../../context';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import differenceInDays from 'date-fns/differenceInDays';
import { useSnackbar } from 'notistack';
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
  useContext,
  useMemo,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ConfirmPrompt, Stepper, UserFilter, SaveButton } from '../../components';
import {
  getOptimizedRoute,
  getOptimizeRoutesMethods,
  postRoutesToOptimize,
  postRoutesToOptimizeLater,
  updateServiceRoutes,
  getTechnicianUsers,
} from '../../fetch';
import {
  ICalendarDateRange,
  IDateRange,
  IDropdownResponse,
  IFinalRoutePayload,
  IListUser,
  IRouteUpdateMode,
  IServiceChange,
  IServiceRoute,
  IUpdateRoutesPayload,
  ICalendarView,
  ITechnicianUser,
  IUser,
} from '../../models';
import { DateRangeFilter } from './DateRangeFilter';
import { ServiceRoutesPods } from './ServiceRoutesPods';
import { useRouteChanges } from './useRouteChanges';
import {
  buildChangeState,
  buildFlatTechnicianList,
  colorizeRoute,
  toChangeListPayload,
} from './utils';
import { MapModal } from './map-modal';
import { UpdateModeSelect } from './UpdateModeSelect';
import { isNil, uniqBy } from 'lodash';
import { useConfirm, useUnload } from '../../hooks';
import { formatDate } from '../../helpers';
import { defaultUnsavedChangesMessage } from '../../constants';
import { deepEqual } from 'fast-equals';
import { appInsights } from '../../services';

const PERM_CHANGES_MAX_ALLOWED_DAYS = 7;
const ERROR_MESSAGE = 'Error optimizing these routes, please try again.';
const ERROR_MESSAGE_MISSING = 'Error finding these optimized routes, please try again.';
const SUCCESS_MESSAGE = 'Your service route updates have been updated successfully!';

enum steps {
  review,
  optimize,
  adjust,
  save,
}
interface IOptimizeRoutes {
  loadingServiceRoutes: boolean;
  activeStep: number;
  setActiveStep: (val: any) => void;
  setKey: Dispatch<SetStateAction<string>>;
  optimizedRouteId?: string | null;
  dateRange?: ICalendarDateRange;
  updateMode: IRouteUpdateMode;
  onUpdateModeChange?: (value: IRouteUpdateMode) => unknown;
}

const stepTitles = ['Select Date', 'Optimize routes', 'Adjust routes', 'Save updated routes'];

const trackCancelOptimization = (user?: IUser) => {
  appInsights.trackEvent({ name: 'Canceled optimization', properties: { user: user } });
};

export const OptimizeRoutes: FC<IOptimizeRoutes> = ({
  activeStep,
  setActiveStep,
  setKey,
  optimizedRouteId,
  loadingServiceRoutes,
  dateRange,
  updateMode,
  onUpdateModeChange,
}) => {
  const { user } = useContext(UserContext);
  const { setSelectedTechs } = useContext(ServiceRoutesContext);
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [message, setMessage] = useState('');
  const [originalRoutes, setOriginalRoutes] = useState<IServiceRoute[]>([]);
  const [optimizedRoutes, setOptimizedRoutes] = useState<IServiceRoute[]>([]);
  const [originalChanges, setOriginalChanges] = useState<Record<string, IServiceChange>>({});
  const [disableUpdateMode, setDisableUpdateMode] = useState(true);
  const [showMapModal, setShowMapModal] = useState(false);
  const [currentRouteIndex, setCurrentRouteIndex] = useState<number | null>(null);
  const [shouldStillOptimze, setShouldStillOptimze] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState<ICalendarDateRange | undefined>();
  const [users, setUsers] = useState<IListUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<IListUser[]>([]);
  const [routeOptimizationId, setRouteOptimizationId] = useState<string>('');

  const selectedUserIds = useMemo(() => selectedUsers.map(u => u.userId), [selectedUsers]);
  useEffect(() => {
    const loadFilters = async () => {
      const res = await getTechnicianUsers({
        perPage: -1,
        roles: 'ServiceTech',
        isDisabled: false,
      });
      setUsers(
        res.records.map(
          (user: ITechnicianUser): IListUser => ({
            userId: user.userId,
            userName: user.userName,
            loginName: user.loginName ?? '',
            isDisabled: user.isDisabled,
            inventoryLocationId: user.inventoryLocationId ?? '',
            inventoryLocationDescription: user.inventoryLocationDescription ?? '',
            daysAvailable: user.daysAvailable ?? [],
          })
        )
      );
    };
    loadFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserRemove = (user: IListUser) => {
    const newSelectedUsers = selectedUsers.filter(u => u !== user);
    setSelectedUsers(newSelectedUsers);
  };

  useEffect(() => {
    setSelectedDateRange(dateRange);
  }, [dateRange]);

  const {
    updatedRoutes,
    hasChanges,
    changes,
    onServicesChange,
    setInitialRoutes,
    reset,
    setChanges,
  } = useRouteChanges({
    serviceRoutes: originalRoutes,
    updatedRoutes: optimizedRoutes,
    changes: originalChanges,
    updateMode: 'Single',
  });

  const daysOptimized = useMemo(() => {
    if (!selectedDateRange?.startDate || !selectedDateRange?.endDate) {
      return 0;
    }
    const { startDate, endDate } = selectedDateRange;
    return differenceInDays(endDate, startDate);
  }, [selectedDateRange]);

  useEffect(() => {
    if (daysOptimized > PERM_CHANGES_MAX_ALLOWED_DAYS) {
      onUpdateModeChange?.('Single');
      setDisableUpdateMode(true);
    } else {
      setDisableUpdateMode(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysOptimized]);

  const currentRoute = useMemo(() => {
    if (isNil(currentRouteIndex) || !updatedRoutes) {
      return undefined;
    }
    return colorizeRoute(updatedRoutes[currentRouteIndex]);
  }, [currentRouteIndex, updatedRoutes]);

  const location = useLocation();
  const history = useHistory();

  const classes = useStyles();

  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => stepTitles.length;

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const onMapClick = (routeIndex: number) => {
    setShowMapModal(true);
    setCurrentRouteIndex(routeIndex);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          stepTitles.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleReset = () => {
    setSelectedDateRange(undefined);
    if (activeStep === steps.save) {
      setKey('adjust');
    } else {
      setKey('optimize');
    }
    setActiveStep(steps.review);
    setSelectedMethod('Distance');
    setMessage('');
    setShouldStillOptimze(false);
    setCompleted({});
    setChanges({});
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const completeAllSteps = () => {
    const newCompleted = completed;
    newCompleted[steps.review] = true;
    newCompleted[steps.optimize] = true;
    newCompleted[steps.adjust] = true;
    newCompleted[steps.save] = true;
    setCompleted(newCompleted);
  };

  const optimizeRoutes = async (shouldHandleComplete?: boolean) => {
    try {
      if (!selectedDateRange) {
        return;
      }
      const res = await postRoutesToOptimize({
        startDate: formatDate(selectedDateRange.startDate),
        endDate: formatDate(selectedDateRange.endDate),
        optimizeMethod: selectedMethod || 'Distance',
        officeId: user?.officeId,
        userIds: selectedUserIds,
      });

      if (res.changes?.length === 0) {
        const message =
          res.original.length === 0
            ? 'No routes found to optimize. Optimizing requires a minimum of 3 sites on the route.'
            : 'Routes are already in optimal order';

        enqueueSnackbar(message, {
          variant: 'info',
        });
        setActiveStep(steps.review);
        return;
      }

      const changes = buildChangeState(res.original, res.changes);
      setOriginalRoutes(res.original);
      setOptimizedRoutes(res.modified);
      setOriginalChanges(changes);
      setRouteOptimizationId(res.routeOptimizationId);
      if (selectedUserIds.length) {
        const filteredTechs = buildFlatTechnicianList(res.original).filter(u =>
          selectedUserIds.some(id => id === u.userId)
        );
        const technicians = uniqBy(filteredTechs, u => u.userId);
        setSelectedTechs(technicians);
      }
      if (shouldHandleComplete) {
        handleComplete();
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e) {
      enqueueSnackbar(`Error optimizing routes, please try again.`, {
        variant: 'error',
      });
    }
  };
  const optimizeRoutesLater = async () => {
    try {
      if (!selectedDateRange) {
        return;
      }
      await postRoutesToOptimizeLater({
        emailWhenProcessed: true,
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        optimizeMethod: selectedMethod || 'Distance',
        officeId: user?.officeId,
      });

      setMessage(
        'An email will be sent when these routes have been optimized. Would you like to still proceed to optimize now?'
      );
      setShouldStillOptimze(true);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e: any) {
      setMessage(ERROR_MESSAGE);
      completeAllSteps();
      setActiveStep(steps.save);
    }
  };

  const getOptimizedRoutesFromLater = async () => {
    try {
      if (!optimizedRouteId) {
        setMessage(ERROR_MESSAGE_MISSING);
      }
      const res = await getOptimizedRoute(optimizedRouteId as string);

      setOptimizedRoutes(res);
      handleComplete();
      setActiveStep(steps.adjust);

      window.scrollTo({ top: 0, behavior: 'smooth' });
    } catch (e: any) {
      setMessage(ERROR_MESSAGE_MISSING);
      completeAllSteps();
      setActiveStep(steps.save);
    }
  };
  const [isSaving, setIsSaving] = useState(false);
  const saveOptimizedRoutes = async () => {
    setIsSaving(true);
    try {
      const finalOrder: IFinalRoutePayload[] = updatedRoutes.map(route => {
        return {
          serviceDate: route.serviceDate,
          routes: route.technicians.map(tech => {
            return {
              userId: tech.userId,
              scheduledServiceIds: tech.services.map(service => {
                return service.scheduledServiceId;
              }),
            };
          }),
        };
      });
      // the user moves services around after the api has sent back optimized routes
      const hasMadeAdditionalChanges = !deepEqual(optimizedRoutes, updatedRoutes);

      const payload: IUpdateRoutesPayload = {
        updateMode,
        changes: toChangeListPayload(changes),
        finalOrder,
        routeOptimizationId,
        madeAdditionalChanges: hasMadeAdditionalChanges,
      };
      await updateServiceRoutes(payload);
      setInitialRoutes(updatedRoutes);
      handleComplete();
      setMessage(SUCCESS_MESSAGE);
    } catch (e: any) {
      setMessage('Error saving these routes, please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const [optimizeMethods, setOptimizeMethods] = useState<IDropdownResponse[]>([]);
  const [selectedMethod, setSelectedMethod] = useState<string>('Distance');
  const [isLoadingOptimizationMethods, setIsLoadingOptimizationMethods] = useState(false);
  const fetchOptimizeRouteMethods = async (range?: IDateRange | null) => {
    setIsLoadingOptimizationMethods(true);
    try {
      const res = await getOptimizeRoutesMethods();
      if (res && res.Detail) {
        return enqueueSnackbar(`Error, ${res.Detail}`, {
          variant: 'error',
        });
      } else if (
        res &&
        res.Errors &&
        Object.values(res.Errors)[0] &&
        Object.values(Object.values(res.Errors)[0] as any)[0]
      ) {
        return enqueueSnackbar(`Error, ${Object.values(Object.values(res.Errors)[0] as any)[0]}`, {
          variant: 'error',
        });
      }
      setOptimizeMethods(res);
      // Get distinct technicians for filtering
    } catch (error: any) {
      if (error?.Detail) {
        return enqueueSnackbar(`Error, ${error.Detail}`, {
          variant: 'error',
        });
      }

      if (!error?.Detail) {
        return enqueueSnackbar(`We were unable fetch the Optimization Methods.`, {
          variant: 'error',
        });
      }
    }
    setIsLoadingOptimizationMethods(false);
  };

  useEffect(() => {
    fetchOptimizeRouteMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeStep === steps.optimize && !optimizedRouteId) {
      // If it will take more than 2 weeks to optimize the routes, call optimizeLater endpoint
      let shouldOptimizeLong = false;

      if (selectedDateRange) {
        shouldOptimizeLong =
          differenceInDays(
            selectedDateRange.endDate ?? new Date(),
            selectedDateRange.startDate ?? new Date()
          ) > 13;
      }

      if (!shouldOptimizeLong) {
        optimizeRoutes(true);
      } else {
        optimizeRoutesLater();
      }
    }
    if (activeStep === steps.optimize && optimizedRouteId) {
      getOptimizedRoutesFromLater();
      setActiveStep(steps.optimize);
      // Remove search params after
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has('optimizeRouteId')) {
        queryParams.delete('optimizeRouteId');
        history.replace({
          search: queryParams.toString(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (optimizedRouteId) {
      getOptimizedRoutesFromLater();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optimizedRouteId]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [activeStep]);

  useUnload((e: any) => {
    e.preventDefault();
    e.returnValue = '';
  }, activeStep === steps.optimize);

  const shouldShowCancelButton = () => {
    if (activeStep !== steps.review && activeStep !== steps.optimize) {
      return true;
    }
    return false;
  };

  const cancelButton = (
    <Button
      onClick={async () => {
        if (hasChanges) {
          const result = await confirm(defaultUnsavedChangesMessage);
          if (!result) {
            return;
          }
        }
        if (activeStep === steps.adjust || activeStep === steps.optimize) {
          trackCancelOptimization(user);
        }
        handleReset();
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has('optimizeRouteId')) {
          queryParams.delete('optimizeRouteId');
          history.replace({
            search: queryParams.toString(),
          });
        }
      }}
      color="inherit"
    >
      {activeStep === steps.save ? 'Back to Routes' : 'Cancel'}
    </Button>
  );
  return (
    <>
      <ConfirmPrompt when={activeStep === steps.optimize} message={defaultUnsavedChangesMessage} />
      <Box mt={2} mb={2}>
        <Stepper steps={stepTitles} activeStep={activeStep} completed={completed} />
      </Box>
      {/* Step 1 let them pick the dates to optimize */}
      {activeStep === steps.review && (
        <Grid
          container
          spacing={2}
          maxWidth={{ xs: '100%', xl: '93.5%' }}
          margin="0 auto"
          justifyContent="center"
        >
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Typography>Select Routes by Date</Typography>
            <DateRangeFilter
              allowNavigation={false}
              selectedDateRange={selectedDateRange}
              setSelectedDateRange={setSelectedDateRange}
              hasChanges={hasChanges}
              view={ICalendarView.DateRange}
            />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <div className={classes.techFilter}>
              <Typography>Filter by Technician</Typography>
              <UserFilter
                className={classes.filterControl}
                users={users}
                selectedUsers={selectedUsers}
                onChange={users => setSelectedUsers(users)}
                onDelete={handleUserRemove}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6} lg={4} xl={3}>
            <Typography>Optimization Method</Typography>
            <FormControl fullWidth variant="outlined">
              <Select
                disabled={isLoadingOptimizationMethods}
                name="optimizeRouteOptions"
                labelId="optimizeRouteOptions"
                id="optimizeRouteOptions"
                value={selectedMethod}
                onChange={e => {
                  setSelectedMethod(e.target.value);
                }}
              >
                {optimizeMethods &&
                  optimizeMethods.map((optimizeMethod, index) => {
                    return (
                      <MenuItem key={`${index}`} value={optimizeMethod.value}>
                        {optimizeMethod.description}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} lg={'auto'} xl={'auto'}>
            <Box>
              <Typography>Select Update Method</Typography>
              <Box mt={1}>
                <UpdateModeSelect
                  value={updateMode}
                  onChange={onUpdateModeChange}
                  disabled={disableUpdateMode}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button
                disabled={
                  (!selectedDateRange?.startDate && !selectedDateRange?.endDate) || !selectedMethod
                }
                onClick={handleComplete}
                color="primary"
                startIcon={<FontAwesomeIcon icon={faRoute} />}
              >
                Optimize
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
      <Box margin="0 auto">
        {activeStep === steps.optimize && (
          <>
            {shouldStillOptimze && (
              <Box className={classes.progressContainer}>
                <Typography sx={{ color: theme => theme.palette.primary.main }} variant={'h5'}>
                  {message}
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  marginTop={2}
                  justifyContent="center"
                  gap={1}
                >
                  {cancelButton}

                  <Button
                    onClick={() => {
                      setShouldStillOptimze(false);
                      optimizeRoutes(true);
                      setMessage(SUCCESS_MESSAGE);
                    }}
                    color="primary"
                    disabled={isSaving}
                  >
                    Proceed
                  </Button>
                </Box>
              </Box>
            )}
            {!shouldStillOptimze && (
              <Box className={classes.progressContainer}>
                <StyledLinearProgress
                  className={classes.progressBar}
                  color={'secondary'}
                  variant="indeterminate"
                />
                <Typography sx={{ color: theme => theme.palette.primary.main }} variant={'h5'}>
                  {optimizedRouteId
                    ? 'Loading your optimized service routes'
                    : 'Your service routes are being optimized'}
                </Typography>
              </Box>
            )}
          </>
        )}
      </Box>
      <Box>
        {activeStep === steps.adjust && (
          <Box mt={2}>
            <ConfirmPrompt
              when={hasChanges}
              message={defaultUnsavedChangesMessage}
              onConfirm={() => {
                trackCancelOptimization(user);
              }}
            />
            <ServiceRoutesPods
              isSaving={isSaving}
              isLoading={loadingServiceRoutes}
              serviceRoutes={updatedRoutes}
              showWeekViewSelector={false}
              showSave={false}
              showReset={false}
              disableUpdateMode={disableUpdateMode}
              hasChanges={hasChanges}
              changes={changes}
              onServicesChange={onServicesChange}
              updateMode={updateMode}
              onUpdateModeChange={onUpdateModeChange}
              onMapClick={onMapClick}
              serviceRouteType="optimize"
              allowedTechIds={selectedUserIds}
              toolbarControls={
                <>
                  {shouldShowCancelButton() && <Grid item>{cancelButton}</Grid>}
                  <Grid item>
                    <SaveButton
                      handleSave={saveOptimizedRoutes}
                      disabled={isSaving}
                      text={isSaving ? 'Saving...' : 'Save'}
                    />
                  </Grid>
                </>
              }
            />

            {currentRoute && (
              <MapModal
                isOpen={showMapModal}
                onClose={() => {
                  setCurrentRouteIndex(null);
                  setShowMapModal(false);
                }}
                serviceRoute={currentRoute}
                onServicesChange={onServicesChange}
                updatedRoutes={updatedRoutes}
                changes={changes}
                updateMode={updateMode}
                hasChanges={hasChanges}
                reset={reset}
                currentRouteIndex={currentRouteIndex}
              />
            )}
          </Box>
        )}
      </Box>
      {activeStep === steps.save && (
        <>
          <Box className={classes.progressContainer}>
            {message.includes('successfully!') && (
              <FontAwesomeIcon className={classes.checkmark} icon={faSquareCheck} />
            )}
            <Typography className={classes.progressDescription} variant={'h5'}>
              {message}
            </Typography>
            <Box mt={1}>{cancelButton}</Box>
          </Box>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles<Theme>(theme => ({
  progressContainer: {
    textAlign: 'center',
    maxWidth: '550px',
    margin: '0 auto',
  },
  progressBar: {
    margin: theme.spacing(2, 0.5, 4),
  },
  buttons: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(3),
    textAlign: 'center',
    justifyContent: 'center',
  },
  checkmark: {
    fontSize: theme.typography.h1.fontSize,
    color: theme.palette.primary.main,
  },
}));

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  backgroundColor: theme.palette.grey[300],
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`&.${linearProgressClasses.barColorSecondary}`]: {
    backgroundColor: theme.palette.grey[400],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));
