import { FC, useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { filesize } from 'filesize';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
  ListItem,
  ListItemButton,
} from '@mui/material';
import { CardTitle } from '../card';
import { DocumentsLayout } from './DocumentsLayout';
import { IDocument, IDocumentPUT } from '../../models';
import { formatDate, axios, downloadFile } from '../../helpers';
import { DocumentModal } from './DocumentModal';
import { useConfirm } from '../../hooks';
import { GridDataFetcher, ServerSideDataGrid, useDataGrid } from '../data-grid';
import { GridColDef, GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid';
import { TableActionsMenu } from '../table';

interface IDocumentsProps {
  isEditable?: boolean;
  showPagination?: boolean;
  display?: 'card' | 'div';
  isCollapsible?: boolean;
  initialExpand?: boolean;
  marginTop?: number;
  cardTitle?: string;
  labelContext?: string;
  getApiRequest: (filters?: any) => Promise<any>;
  getFilters?: any;
  postFilterId?: string | number;
  deleteApiRequest?: (accountDocumentId: string | number) => Promise<void>;
  postApiRequest?: (
    accountId: string | number,
    payload: File | string,
    description?: string
  ) => Promise<any>;
  putApiRequest?: (payload: IDocumentPUT, accountDocumentId: string | number) => Promise<any>;
  disableDelete?: (item: any) => boolean;
  isExpanded?: boolean;
  hideOnEmpty?: boolean;
  hasWrapper?: boolean;
  gridKeyName: string;
}

export const Documents: FC<IDocumentsProps> = ({
  isEditable = true,
  showPagination = true,
  display = 'card',
  isCollapsible = false,
  initialExpand = false,
  marginTop,
  cardTitle,
  labelContext,
  getApiRequest,
  getFilters,
  postFilterId,
  deleteApiRequest,
  postApiRequest,
  putApiRequest,
  disableDelete,
  isExpanded,
  hideOnEmpty = false,
  hasWrapper = false,
  gridKeyName,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width: 468px)');

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<IDocument | null>(null);

  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);
  const [isDeletingDocument, setIsDeletingDocument] = useState(false);

  const confirm = useConfirm();

  const dataFetcher: GridDataFetcher<IDocument> = useCallback(
    async ({ sortColumn, sortDirection, page, perPage }) => {
      try {
        const res = await getApiRequest({
          perPage: showPagination ? perPage : -1,
          sortBy: sortColumn || 'whenUploaded',
          sortDirection: sortDirection || 'desc',
          page: page + 1,
          ...getFilters,
        });
        return {
          rows: res.records,
          rowCount: res.totalRecordCount,
        };
      } catch (error: any) {
        enqueueSnackbar(
          error?.Detail ||
            `Error loading the ${
              labelContext ? labelContext?.toLowerCase() + ' ' : ''
            }documents, please try again.`,
          {
            variant: 'error',
          }
        );
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    rows,
    isLoading: isLoadingDocuments,
    refetch: fetchDocuments,
    page,
    pageSize: perPage,
    rowCount: recordCount,
    sortModel,
    onPageChange,
    onPageSizeChange,
    onSortModelChange,
  } = useDataGrid<IDocument>({
    initialOptions: {
      page: 0,
      pageSize: 10,
      gridKeyName: gridKeyName,
      sortColumn: 'whenUploaded',
      sortDirection: 'desc',
    },
    dataFetcher,
  });

  const removeDocument = async (accountDocumentId: string) => {
    setIsDeletingDocument(true);
    try {
      if (deleteApiRequest) {
        await deleteApiRequest(accountDocumentId);
        enqueueSnackbar(`${labelContext ? labelContext + ' ' : ''}Document Deleted!`, {
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar(
        `Error deleting the ${
          labelContext ? labelContext?.toLowerCase() + ' ' : ''
        }document, please try again.`,
        {
          variant: 'error',
        }
      );
    } finally {
      setIsDeletingDocument(false);
      fetchDocuments();
    }
  };

  const handleDocumentDelete = async (document: IDocument) => {
    const result = await confirm('Are you sure you want to delete this?');
    if (result) {
      removeDocument(document.accountDocumentId);
    } else {
      return;
    }
  };

  const handleDocumentDownload = async (document: IDocument) => {
    setIsDownloadingDocument(true);
    try {
      const res = await axios.get(`/api/account-documents/${document.accountDocumentId}/download`, {
        responseType: 'blob',
      });
      //Create a Blob from the PDF
      const blob = new Blob([res.data], { type: 'application/pdf' });
      downloadFile(blob, document.originalFileName ?? 'repair-agreement.pdf');
    } catch (error: any) {
      enqueueSnackbar(error?.Detail ?? `Error downloading file, please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsDownloadingDocument(false);
    }
  };

  const getDocType = (document: IDocument) => {
    const docType =
      document?.originalFileName?.substring(
        document?.originalFileName?.lastIndexOf('.') + 1,
        document?.originalFileName?.length
      ) ?? '--';
    return docType;
  };

  const addDocumentButton =
    isEditable && postApiRequest ? (
      <Button
        color="secondary"
        size="small"
        onClick={() => setModalOpen(true)}
        title={`Add ${labelContext} Document`}
        startIcon={<FontAwesomeIcon icon={faPlusCircle} />}
      >
        Add {labelContext ?? ''} Document
      </Button>
    ) : undefined;
  const columns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'description',
        headerName: 'Description',
        disableColumnMenu: true,
        flex: 1.5,
        minWidth: 150,
        valueGetter: (params: GridValueGetterParams<IDocument>) => {
          const { row: document } = params;
          return document.description || '--';
        },
      },
      {
        field: 'originalFileName',
        headerName: 'File Name',
        disableColumnMenu: true,
        flex: 1.5,
        minWidth: 150,
        renderCell: (params: GridRenderCellParams<IDocument>) => {
          const { row: document } = params;
          const fileName = document.originalFileName
            ? document.originalFileName.substring(0, document.originalFileName.lastIndexOf('.')) ??
              document.originalFileName
            : '--';
          return (
            <Typography
              noWrap
              component="span"
              sx={{
                width: '100%',
                maxWidth: isMobile ? '50%' : 300,
                fontWeight: 'inherit',
                fontSize: 'inherit',
              }}
              title={fileName}
              display="inline-block"
            >
              {fileName}
            </Typography>
          );
        },
      },
      {
        field: 'docType',
        headerName: 'Doc Type',
        disableColumnMenu: true,
        minWidth: 100,
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<IDocument>) => {
          const { row: document } = params;
          return (
            <Typography component="span" style={{ textTransform: 'uppercase' }}>
              <strong>{getDocType(document)}</strong>
            </Typography>
          );
        },
      },
      {
        field: 'whenUploaded',
        headerName: 'Upload Date',
        disableColumnMenu: true,
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<IDocument>) => {
          const { row: document } = params;

          return (
            <span title={isEditable ? undefined : 'Upload Date'}>
              {!!document.whenUploaded ? formatDate(document.whenUploaded) : '--'}
            </span>
          );
        },
      },
      {
        field: 'size',
        headerName: 'Size',
        disableColumnMenu: true,
        width: 112,
        align: 'right',
        headerAlign: 'right',
        renderCell: (params: GridRenderCellParams<IDocument>) => {
          const { row: document } = params;

          return (
            <span title={isEditable ? undefined : 'Size'}>
              {document.size ? filesize(document.size, { base: 2, standard: 'jedec' }) : '0'}
            </span>
          );
        },
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        minWidth: 120,
        maxWidth: 300,
        align: 'center',
        flex: 1,
        renderCell: (params: GridRenderCellParams<IDocument>) => {
          const { row: document } = params;

          return (
            <TableActionsMenu
              labelContext="Documents"
              id={`action-menu-${document.accountDocumentId}`}
            >
              {document?.fileLocation && (
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ color: theme => theme.palette.primary.main }}
                    disabled={isLoadingDocuments || isDeletingDocument || isDownloadingDocument}
                    title={`Download Document: ${document.description}`}
                    onClick={() => handleDocumentDownload(document)}
                  >
                    Download Document
                  </ListItemButton>
                </ListItem>
              )}
              {isEditable && putApiRequest && (
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ color: theme => theme.palette.primary.main }}
                    disabled={isLoadingDocuments || isDeletingDocument}
                    title={`Edit Document: ${document.description}`}
                    onClick={() => {
                      setSelectedDocument(document);
                      setModalOpen(true);
                    }}
                  >
                    Edit Document
                  </ListItemButton>
                </ListItem>
              )}
              {deleteApiRequest && isEditable && (
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ color: theme => theme.palette.error.main }}
                    disabled={
                      isLoadingDocuments ||
                      isDeletingDocument ||
                      (disableDelete && disableDelete(document) !== false)
                    }
                    title={`Delete Document: ${document.description}`}
                    onClick={() => handleDocumentDelete(document)}
                  >
                    Delete Document
                  </ListItemButton>
                </ListItem>
              )}
            </TableActionsMenu>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDocuments, isEditable, isMobile]);

  const renderCardContent = () => {
    return (
      <>
        {display === 'div' && (
          <>
            {addDocumentButton}
            <Divider
              sx={{ marginTop: theme => theme.spacing(2), marginBottom: theme => theme.spacing(2) }}
            />
          </>
        )}
        <ServerSideDataGrid
          autoHeight
          getRowId={(row: IDocument) => row.accountDocumentId!}
          rows={rows}
          columns={columns}
          disableRowSelectionOnClick
          columnHeaderHeight={36}
          page={page}
          pageSize={perPage}
          sortModel={sortModel}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          onSortModelChange={onSortModelChange}
          rowCount={recordCount}
          hideFooter={!showPagination}
          loading={isLoadingDocuments || isDownloadingDocument}
          noResultsMessage={`There are no ${
            labelContext ? labelContext?.toLowerCase() + ' ' : ''
          }documents to display.`}
          hasMobileLayout
          mobileProps={{
            mobileDefaultAccessor: 'description',
            truncateAccordionLabel: true,
            showHandleActions: true,
          }}
        />
      </>
    );
  };
  const renderDocContent = (
    <DocumentsLayout display={display} marginTop={marginTop}>
      {display === 'card' && (
        <CardTitle
          title={cardTitle ?? `${labelContext ? `${labelContext} ` : ''}Documents`}
          action={addDocumentButton}
          mobileWrap
          withExpand={isCollapsible}
          initialExpand={initialExpand}
          overrideExpand={isExpanded}
        >
          {renderCardContent()}
        </CardTitle>
      )}
      {display === 'div' && renderCardContent()}

      {isEditable && postApiRequest && putApiRequest && postFilterId && (
        <DocumentModal
          title={`${labelContext ? `${labelContext} ` : ''}Document`}
          isOpen={isModalOpen}
          onClose={() => {
            setModalOpen(false);
            setSelectedDocument(null);
          }}
          selectedDocument={selectedDocument}
          isLoading={isLoadingDocuments}
          reloadList={fetchDocuments}
          postApiRequest={postApiRequest}
          putApiRequest={putApiRequest}
          postFilterId={postFilterId}
          inputId={`${gridKeyName}-document-upload`}
        />
      )}
    </DocumentsLayout>
  );
  if (hideOnEmpty && !isEditable && rows && rows?.length === 0) {
    return null;
  }
  if (hasWrapper) {
    return <Box>{renderDocContent}</Box>;
  }
  return <>{renderDocContent}</>;
};
